
import { NavLink } from 'react-router-dom'
function Footer() {
    
    return(

        <footer>
              <nav>
                    <NavLink to="/"> <p>Главная</p> </NavLink>
                    <NavLink to="/catalog"> <p>Каталог</p> </NavLink>
                    <NavLink to="/news"> <p>Новости</p> </NavLink>
                    <NavLink to="/collection"> <p>Подборки</p> </NavLink>
                </nav>
            <div className="social">

         
            </div>
        </footer>

    )
}

export default Footer