import { useState, useEffect } from 'react';
import axios from 'axios';
import bookmark from '../icons/main/bookmark.svg';
import { NavLink } from 'react-router-dom';
import LoadingSpinner from './loadingIcon';

import {motion} from 'framer-motion'

function Banner() {
    const [animeData, setAnimeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [favorites, setFavorites] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        setLoading(true);
        const fetchLatestAnime = async () => {
            try {
                const response = await axios.get(' http://185.177.219.190/api/latestAnime');
                setAnimeData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при получении самых свежих аниме:', error.message);
            }
        };

        fetchLatestAnime();

        const fetchFavorites = async () => {
            try {
                const response = await axios.get(' http://185.177.219.190/api/userFavoriteAnime', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                setFavorites(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Ошибка при получении избранных аниме:', error.message);
            }
        };

        fetchFavorites();

        const intervalId = setInterval(scrollSlider, 30000);

        return () => clearInterval(intervalId);
    }, []);


    
    const removeFromFavorites = async (animeId) => {
        try {
            const response = await axios.post(' http://185.177.219.190/api/removeFromFavorite', { animeId }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
       
    

            try {
                const favoritesResponse = await axios.get(' http://185.177.219.190/api/userFavoriteAnime', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                setFavorites(favoritesResponse.data);
            } catch (error) {
                console.error('Ошибка при получении избранных аниме:', error.message);
            }
            
        } catch (error) {
            console.error('Ошибка при удалении аниме из избранного:', error.message);
        }
    };


    function scrollSlider() {
        const slider = document.querySelector('.banner-slider');
        if (!slider) return;

        const firstSlide = slider.children[0];
        if (!firstSlide) return;

        const slideWidth = firstSlide.offsetWidth;
        slider.style.transition = 'transform 0.5s ease-in-out';
        slider.style.transform = `translateX(-${slideWidth}px)`;

        setTimeout(() => {
            slider.style.transition = 'none';
            slider.appendChild(firstSlide);
            slider.style.transform = 'translateX(0)';
        }, 1000);
    }

    const addToFavorites = async (animeId) => {
        try {
            const response = await axios.post(' http://185.177.219.190/api/addToFavorite', { animeId }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            console.log('Аниме добавлено в избранное:', response.data);
    
         
            try {
                const favoritesResponse = await axios.get(' http://185.177.219.190/api/userFavoriteAnime', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                setFavorites(favoritesResponse.data);
            } catch (error) {
                console.error('Ошибка при получении избранных аниме:', error.message);
            }
            
        } catch (error) {
            console.error('Ошибка при добавлении аниме в избранное:', error.message);
        }
    };



    const isFavorite = (animeId) => {
        return favorites.some(favorite => favorite.collectionId === animeId);
    };


    return (
        <div className="banner_wrapper">
            <div className='banner-slider'>
            {loading && <LoadingSpinner />}
                {animeData.map(anime => (
                    <div key={anime._id} className='announcement_banner'>
                        <video
                            loop
                            autoPlay
                            muted
                            disablePictureInPicture
                        >
                            <source src={` http://185.177.219.190/api/videos/${anime.title}`} type="video/mp4" />
                        </video>
                        <div className="overlay"></div>
          
                        <div className='announcment_content'>

                        <motion.div
      initial={{ opacity: 0, x: -100 }} 
      animate={{ opacity: 1, x: 0 }} 
      transition={{ delay: 0.5 }} 
    >
                            <div className='announcement_title'>
                                <p>{anime.animeName}</p>
                            </div>
                            </motion.div>
                
                       <div className='announcement_text'>

                       <motion.div
      initial={{ opacity: 0, x: -100 }} 
      animate={{ opacity: 1, x: 0 }} 
      transition={{ delay: 0.5 }} 
    >
                   
                                <p>{anime.animeBannerDescription}</p>
                                </motion.div>  
                            </div>
                      
                            <div className='announcement_buttons'>
                               <NavLink to={`/watch/${anime.collectionId}` } className='about-btn'><p>Подробнее</p></NavLink> 

                               {isFavorite(anime.collectionId) ? (
                                   <button className='bookmark'  onClick={() => removeFromFavorites(anime.collectionId)}> <span className='utf-bookmark'>✅</span> <p>В избранном</p></button>
                               ) : (
                                   <button className='bookmark' onClick={() => addToFavorites(anime.collectionId)}> <img src={bookmark} alt="Bookmark icon" /> <p>В избранное</p></button>
                               )}
                               
                            </div>
                 
                        </div>

                  
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Banner;
