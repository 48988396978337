import bleach from '../News/1/BigPreview/bleach.webp';
import time from '../icons/main/time.svg';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {motion} from 'framer-motion'
import LoadingSpinner from './loadingIcon';
function NewsPage() {
   
    const [allNews, setAllNews] = useState(null)
    const { id: newsId } = useParams();
    const [loading, setLoading] = useState(true); 
   

    useEffect(() => {
        setLoading(true)
        const fetchLatestNews = async () => {
            try {
                const AllNews = await axios.get(` http://185.177.219.190/api/news-page/${newsId}`);
                setAllNews(AllNews.data)
            } catch (error) {
                console.log(error)
            }
        };
        setLoading(false)
        fetchLatestNews();
    }, [newsId]); 

    return (
        
        <motion.div
        initial={{ opacity: 0, y: -100 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 1 }} 
      >
        <div className="NewsPage">
         {loading && <LoadingSpinner />}
            {allNews && (
                
                <div>
                    <div className="NewsPage_banner">
                        <img src={` http://185.177.219.190/api/NewsImages/Banner/${allNews.NewsId}`} />
                        <div className='dark-bg'> </div>
                    </div>

                    <div className='NewsPage_main'>

                        <div className='NewsPage_main_title'>
                        <span className='news-timeStamp'> <img src={time} /> <p>2022-11-01</p> </span>
                            <h2>{allNews.NewsTitle}</h2>
                          
                        </div>

                        <div className='NewsPage_main_secion news_section_one'>
                            <p>{allNews.NewsFirstSection}</p> 
                            <img src={` http://185.177.219.190/api/NewsImages/NewsImage/01/${allNews.NewsId}`} />
                        </div>

                        <div className='NewsPage_main_secion news_section_two'>
                            <p>{allNews.NewsSecondSection}</p> 
                            <img src={` http://185.177.219.190/api/NewsImages/NewsImage/02/${allNews.NewsId}`} />
                        </div>

                        <div className='NewsPage_main_secion news_section_three'>
                            <p>{allNews.NewsThirdSection}</p> 
                        </div>

                    </div>

                </div>
            )}
        </div>
        </motion.div>
    )
}

export default NewsPage;