import { useState, useEffect } from "react";
import axios from "axios";
import header from '../images/header.png';
import eye from '../icons/main/eye.svg';
import close from '../icons/main/close.svg';
import star from '../icons/main//star.svg';
import bookmark from '../icons/main/bookmark.svg';
import checkmark from '../icons/main/checkmark.svg';
import folder from '../icons/main/folder.svg'
import { NavLink } from "react-router-dom";
import deleteIcon from "../icons/main/delete.svg";
import library from '../icons/main/library.svg';

function UserPageLibrary() {
    const [userData, setUserData] = useState('');
    const [loading, setLoading] = useState();
    const [animeWatchingList,setAnimeWatchingList]=useState('')
    const [userFavoriteList, setUserFavoriteList] = useState('');
    const [userWatchedList, setUserWatchedList] = useState('');
    const [userCollectionList, setUserCollectionList] = useState('');
    const [isCollection, setCollection] = useState(false);
    const [selectedAnime, setSelectedAnime] = useState([]);
    const [collectionName,setCollectionName] = useState('');
    const [collectionText,setCollectionText]=useState('')
    const [selectedCollectionId, setSelectedCollectionId] = useState('');
    const token = localStorage.getItem('token');
    const [deleteState,setDeleteState]=useState()

    

    useEffect(() => {

        const fetchUserData = async () => {
            try {
              
                const response = await axios.get(' http://185.177.219.190/api/userData', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
    
              
                const userData = response.data.user;
          
                setUserData(userData)
           
              
              
    
                const animeResponse = await axios.get(' http://185.177.219.190/api/userWatching', {
                    params: {
                        userWatching: userData.userWatching
                    }
                });
    
        
                setAnimeWatchingList(animeResponse.data);
    
                const favoriteResponse = await axios.get(' http://185.177.219.190/api/userFavorite', {
                    params: {
                        userFavorite: userData.userFavorite
                    }
                });
                setUserFavoriteList(favoriteResponse.data);
            

                const watchedResponse = await axios.get(' http://185.177.219.190/api/watched', {
                    params: {
                        userWatched: userData.userWatched
                    }
                });
                setUserWatchedList(watchedResponse.data);
            
                
                const collectionResponse = await axios.get(" http://185.177.219.190/api/animeByUserCollection", {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  },
                    params: {
                        userCollection:userData._id
                    }
                });
       
                setUserCollectionList({
                  titles: collectionResponse.data.titles,
                  collection: collectionResponse.data.collection
                });
            

            } catch (error) {
              

            }
        };
    
        fetchUserData();
    }, []);

    

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };

    const indicatorStyle = {
        left: `${(activeTab - 1) * 25}%`,
        width: `25%`
      };
    
      const toggleCollection = () => {
        setCollection(!isCollection);
    };


    useEffect(() => {
      setLoading(true);
      if (selectedCollectionId !== null) {
          axios.get(` http://185.177.219.190/api/animeByCollection`, {
              params: {
                  collectionIds: selectedCollectionId
              }
          })
          .then(response => {
              setSelectedAnime(response.data);
              setLoading(false);
              
          })
          .catch(error => {
              console.error('Ошибка при загрузке аниме:', error);
          });
      }
  }, [selectedCollectionId]);


  const deleteDataFromDB = async (id) => {
    try {
      const response = await axios.delete(` http://185.177.219.190/api/DeleteCollection/${id}`);
     
      const collectionResponse = await axios.get(" http://185.177.219.190/api/animeByUserCollection", {
        headers: {
          'Authorization': `Bearer ${token}`
        },
          params: {
              userCollection:userData._id
          }
      });

      setUserCollectionList({
        titles: collectionResponse.data.titles,
        collection: collectionResponse.data.collection
      });
  
      
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  }


    return(
      
      
        <div className="UserPageLibrary-main">
            <div className="UserPageLibrary-header">
                <img className="header-bg" src={header} alt="Header Background"></img>
                <div className="dark-bg"></div>
                <div className="UserPageLibrary-pfp">
                {userData && (
    <>
        <img src={` http://185.177.219.190/api/userAvatar/${userData._id}`} alt="User Avatar"></img>
        <h2>{userData.username}</h2>
    </>
)}
                </div>
            </div>

            {deleteState && (
  <div className={deleteState ? "dlt-confirm-main" : "dlt-confirm-main dlt-hidden"}>
    <div className="dlt-confirm-main-item">
      <p>Вы точно хотите удалить подборку "{deleteState.collectionNameForDelete}" ?🗑️</p>
      <div className="dlt-confirm-main-item-btns">
        <button onClick={() => setDeleteState("")}>Отмена</button>
        <button onClick={() => {
          deleteDataFromDB(deleteState.collectionIdForDelete);
          setDeleteState("");
        }}>Удалить</button>
      </div>
    </div>
  </div>
)}


            <div className={`Collection_main ${isCollection ? 'collection-showed' : ""}`}>
                <div className="CollectionPage_bg"></div>
                <div className={`CollectionPage_main ${isCollection ? 'active-collection' : ""}`}>
                    <img className='close-btn' onClick={toggleCollection} src={close} alt="Close Button"/>
                    <h2>{collectionName}</h2>
                    <div className='collection-Text'><p>{collectionText}</p></div>
                
                    
                    {selectedAnime.map((selectedAnimePopup,index) => (
                        <NavLink to={`/watch/${selectedAnimePopup.collectionId}`} className='CollectionPage_main_item' key={selectedAnimePopup.id}>
                            <span className='CollectionPage_main_item-number'><p>{index+1}</p></span>
                            <div className='CollectionPage_main_item-main'>
                                <h2>{selectedAnimePopup.animeName}</h2>
                                <p>{selectedAnimePopup.animeBannerDescription}</p>
                            </div>
                            <span className='CollectionPage_main_item-rate'>
                                <img src={` http://185.177.219.190/api/images/${selectedAnimePopup.title}`} alt="Friren"/>
                                <span className='collection-rate'>
                                    <img src={star} alt="Star Icon"/> <p>{selectedAnimePopup.animeScore}</p>
                                </span>
                            </span>
                        </NavLink>
                    ))}
                </div>
            </div>


            <div className="userPage-tabs">
      <div className="tabs">
        <div
          className={`tab ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleTabClick(1)}
        >
                <img src={eye}/>
          Смотрю ({animeWatchingList && animeWatchingList.length})
        </div> 
        <div
          className={`tab ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => handleTabClick(2)}
        >  
          <img src={bookmark}/>
          Избранное ({userFavoriteList && userFavoriteList.length})
        </div>
        <div
          className={`tab ${activeTab === 3 ? 'active' : ''}`}
          onClick={() => handleTabClick(3)}
        >
            <img src={checkmark}/>
          Просмотренное ({userWatchedList && userWatchedList.length})
        </div>
        <div
          className={`tab ${activeTab === 4 ? 'active' : ''}`}
          onClick={() => handleTabClick(4)}
        >
                <img src={folder}/>
          Подборки ({userCollectionList.collection && userCollectionList.collection.length})
        </div>
        <div className="indicator" style={indicatorStyle}></div>
      </div>
      <div className="tab-content">
        {activeTab === 1 && 
        
        
        
        <div className='Main_Content_items popular-items user-items'> 
       
       {animeWatchingList && animeWatchingList.map(animeTop => (
          <NavLink to={`/watch/${animeTop.collectionId}`} className='Main_Content_item popular-item' key={animeTop.id}>
            <img src={` http://185.177.219.190/api/images/${animeTop.title}`} type="image/jpg" alt={animeTop.animeName} />
            <span className='Main_Content_item_text'>
              <h3>{animeTop.animeName}</h3>
              <div className='Main_Content_item_info'>
                <p className='Main_Content_item__Year'>{animeTop.animeDate}</p>,
                <p className='Main_Content_item__Genre'>{animeTop.animeGenre.split(',')[0]}</p>
              </div>
            </span>
          </NavLink>
        ))}
        
        </div>
        
        
        }



        
        {activeTab === 2 && 
        
            
        <div className='Main_Content_items popular-items user-items'> 
       
       {userFavoriteList && userFavoriteList.map(animeTop => (
          <NavLink to={`/watch/${animeTop.collectionId}`} className='Main_Content_item popular-item' key={animeTop.id}>
            <img src={` http://185.177.219.190/api/images/${animeTop.title}`} type="image/jpg" alt={animeTop.animeName} />
            <span className='Main_Content_item_text'>
              <h3>{animeTop.animeName}</h3>
              <div className='Main_Content_item_info'>
                <p className='Main_Content_item__Year'>{animeTop.animeDate}</p>,
                <p className='Main_Content_item__Genre'>{animeTop.animeGenre.split(',')[0]}</p>
              </div>
            </span>
          </NavLink>
        ))}
        
        </div>
        }
        {activeTab === 3 && 
        
        
                
        <div className='Main_Content_items popular-items user-items'> 
       
       {userWatchedList && userWatchedList.map(animeTop => (
          <NavLink to={`/watch/${animeTop.collectionId}`} className='Main_Content_item popular-item' key={animeTop.id}>
            <img src={` http://185.177.219.190/api/images/${animeTop.title}`} type="image/jpg" alt={animeTop.animeName} />
            <span className='Main_Content_item_text'>
              <h3>{animeTop.animeName}</h3>
              <div className='Main_Content_item_info'>
                <p className='Main_Content_item__Year'>{animeTop.animeDate}</p>,
                <p className='Main_Content_item__Genre'>{animeTop.animeGenre.split(',')[0]}</p>
              </div>
            </span>
          </NavLink>
        ))}
        
        </div>
        
        }
 {activeTab === 4 && 
    <div className='user-collection_items popular-items'> 
        
        {userCollectionList && userCollectionList.collection && userCollectionList.collection.map((collection, index) => (
            <div 
       
                className='user-collection_item'  
                key={collection._id}
                
            >
               <span 
    onClick={() => setDeleteState({
      collectionIdForDelete: collection._id,
      collectionNameForDelete: collection.title  
    })}
    className="user-collection_item__dlt-btn">
    <img src={deleteIcon} alt="Delete"></img>
      </span>

        
              
                    <img 
                    
                    onClick={() => {
                      setCollection(true);
                      setSelectedCollectionId(collection.collectionStorage);
                      setCollectionName(collection.title);
                      setCollectionText(collection.collectionText)
                  }}
                    src={folder} />
               
               
                  <div 
                       onClick={() => {
                        setCollection(true);
                        setSelectedCollectionId(collection.collectionStorage);
                        setCollectionName(collection.title);
                        setCollectionText(collection.collectionText)
                    }}
                  className="user-collection_item_info">
                    <h2>{collection.title}</h2>
                    <p>{collection.collectionStorage.length} Тайтла(-ов)</p>
             
                  </div>
            </div>
        ))}
    </div>
}


      </div>
    </div>


        </div>
    );
}

export default UserPageLibrary;
