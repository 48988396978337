import Banner from './banner'
import { useState,useEffect,useRef } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import close from '../icons/main/close.svg';
import star from '../icons/main/star.svg';
import LoadingSpinner from './loadingIcon';
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import waited1 from '../images/waited1.jpg'
import waited2 from '../images/waited2.jpg'
import waited3 from '../images/waited3.jpg'
import waited4 from '../images/waited4.jpg'
import waited5 from '../images/waited5.jpg'
import waited6 from '../images/waited6.jpg'
import waited7 from '../images/waited7.jpg'
import waited8 from '../images/waited8.jpg'
import waited9 from '../images/waited9.jpg'
import waited10 from '../images/waited10.jpg'

import trending from '../images/trending.png'

function Main() {
    const [animeRating, setAnimeRating] = useState([]);
    const [animeTranding, setAnimeTranding] =useState([])
    const [animeRecomendation, setAnimeRecomendation] =useState([])
    const [collectionAnime, setCollectionAnime] = useState([]);
    const [isCollection, setCollection] = useState(false);
    const [selectedCollectionId, setSelectedCollectionId] = useState(null);
    const [selectedAnime, setSelectedAnime] = useState([]);
    const [collectionName,setCollectionName] = useState('');
    const [loading, setLoading] = useState(true); 
    const [collectionText,setCollectionText]=useState('')

    const trackRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(0);
  

    const [visibleCount, setVisibleCount] = useState(10); 

    
    const showMore = () => {
      setVisibleCount(prevCount => prevCount + 8); 
    };



    const randomCounts = Array.from({ length: 4 }, () => Math.floor(Math.random() * (500 - 100 + 1)) + 100);
    randomCounts.sort((a, b) => b-a);
    const maxCount = Math.max(...randomCounts);
 
    useEffect(() => {
      if (trackRef.current) {
        const firstChild = trackRef.current.children[0];
        if (firstChild) {
          setElementWidth(firstChild.offsetWidth);
        }
      }
    }, [animeRecomendation]);
    
    useEffect(() => {
      setLoading(true);
      if (selectedCollectionId !== null) {
          axios.get(` http://185.177.219.190/api/animeByCollection`, {
              params: {
                  collectionIds: selectedCollectionId
              }
          })
          .then(response => {
              setSelectedAnime(response.data);
              setLoading(false);
          })
          .catch(error => {
              console.error('Ошибка при загрузке аниме:', error);
          });
      }
  }, [selectedCollectionId]);


    useEffect(() => {
      setLoading(true);
      axios.get(' http://185.177.219.190/api/collectionMainPage')
        .then(response => {
          setCollectionAnime(response.data);
          setLoading(false); 
        })
        .catch(error => {
          console.error('Ошибка при получении данных:', error);
        });
    }, []); 

  const toggleCollection = () => {
      setCollection(!isCollection);
  };

    

    useEffect(() => {
      setLoading(true);
        axios.get(' http://185.177.219.190/api/score') 
          .then(response => {
            setAnimeRating(response.data);
            setLoading(false); 
          })
          .catch(error => {
            console.error('Произошла ошибка при получении данных:', error);
          });
      }, []);
    
      useEffect(() => {
        setLoading(true);
        axios.get(' http://185.177.219.190/api/trending') 
          .then(response => {
            setAnimeTranding(response.data);
            setLoading(false); 
          })
          .catch(error => {
            console.error('Произошла ошибка при получении данных:', error);
          });
      }, []);


      useEffect(() => {
        setLoading(true);
        axios.get(' http://185.177.219.190/api/collectionMain')
          .then(response => {
            const AnimeCollecionData = response.data
            setCollectionAnime(AnimeCollecionData);
            setLoading(false); 
         
          })
          .catch(error => {
            console.error('Ошибка при получении данных:', error);
          });
      }, []); 




      useEffect(() => {
        setLoading(true);
        axios.get(' http://185.177.219.190/api/recomendation') 
          .then(response => {
            setAnimeRecomendation(response.data);
            setLoading(false); 
          })
          .catch(error => {
            console.error('Произошла ошибка при получении данных:', error);
          });
      }, []);


    return(
  <>

  
      <div className={`Collection_main ${isCollection ? 'collection-showed' : ""}`}>
                <div className="CollectionPage_bg"></div>
                <div className={`CollectionPage_main ${isCollection ? 'active-collection' : ""}`}>
                    <img className='close-btn' onClick={toggleCollection} src={close} alt="Close Button"/>
                    <h2>{collectionName}</h2>
                    <div className='collection-Text'><p>{collectionText}</p></div>
                    {loading && <LoadingSpinner />}
                    
                    {selectedAnime.map((selectedAnimePopup,index) => (
                        <NavLink to={`/watch/${selectedAnimePopup.collectionId}`} className='CollectionPage_main_item' key={selectedAnimePopup.id}>
                            <span className='CollectionPage_main_item-number'><p>{index+1}</p></span>
                            <div className='CollectionPage_main_item-main'>
                                <h2>{selectedAnimePopup.animeName}</h2>
                                <p>{selectedAnimePopup.animeBannerDescription}</p>
                            </div>
                            <span className='CollectionPage_main_item-rate'>
                                <img src={` http://185.177.219.190/api/images/${selectedAnimePopup.title}`} alt="Friren"/>
                                <span className='collection-rate'>
                                    <img src={star} alt="Star Icon"/> <p>{selectedAnimePopup.animeScore}</p>
                                </span>
                            </span>
                        </NavLink>
                    ))}
                </div>
            </div>
        <Banner></Banner>
        <div className='Main_Content'>


            <div className='Main_Content_list recomendation'>

            <h2>Специально для вас</h2>

            <motion.div
      initial={{ opacity: 0, y: -100 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ delay: 1 }} 
    >
        <div  className='Main_Content_items special-items'> 
      
  
      
  
        <Swiper
      modules={[Navigation, Scrollbar]}
      navigation
      spaceBetween={15}
      slidesPerView={5}
      className='Main_Content_items forYou'
    
    >
    
         
        {animeRecomendation.map((AnimeRecomendation, index) => (
          
          <SwiperSlide className='Main_Content_item special-item'  key={index}>
                <div class="overlay-images"></div>
            <NavLink to={`/watch/${AnimeRecomendation.collectionId}`}>  <span className='upper-score'> <img src={star}></img> <p> {AnimeRecomendation.animeScore}</p> </span>
                    
                    <img src={` http://185.177.219.190/api/images/${AnimeRecomendation.title}`}></img>
    
                    <span className='Main_Content_item_text'>
    
                    <h3> {AnimeRecomendation.animeName}</h3>
    
                    <div className='Main_Content_item_info'>
                    <p className='Main_Content_item__Year'>{AnimeRecomendation.animeDate}</p>,
    
                    <p className='Main_Content_item__Genre'>{AnimeRecomendation.animeGenre.split(',')[0]}</p>
    
                    </div>
              
                    </span>
                      </NavLink>
          </SwiperSlide>
       
        ))}
 
      </Swiper>
  
   
       


     
              
               
               
              
           
        
    
            
          
            </div>
     </motion.div>

            </div>


            <div className='Main_Content_Collections'>
              
                <h2>Лучшие подборки</h2>
   
                <div className='Main_Content_Collections__items '>
  {loading && <LoadingSpinner />}
  {Array.isArray(collectionAnime.mergedCollection) && collectionAnime.mergedCollection.slice(0, 3).map((collection, index) => (
    <div 
      onClick={() => {
        setCollection(true);
        setSelectedCollectionId(collection.collectionStorage);
        setCollectionName(collection.title);
        setCollectionText(collection.collectionText);
      }}
      className='Main_Content_Collections__item main-collection-item' key={index}
    >
      <h3>{collection.collectionTitle || collection.title}</h3>
      
      <div className='first-card'>
        <img src={`http://185.177.219.190/api/images/${collection.titleStorage[0] || collection.title}`} alt="First Card" />
      </div>
      <div className='second-card'>
        <img src={`http://185.177.219.190/api/images/${collection.titleStorage[1] || collection.title}`} alt="Second Card" />
      </div>
      <div className='third-card'>
        <img src={`http://185.177.219.190/api/images/${collection.titleStorage[2] || collection.title}`} alt="Third Card" />
      </div>
    </div>
  ))}
</div>


            </div>


            <div className="Main_Content_Top">  
         
              <section className='Rate_wrapper'>
             
                <div className='Main_Content_Top__rating'>
                <h2>🏆 Топ недели</h2>
                <div className='rating_content'>
            <h3 className='rating_content__title'>Рейтинг</h3>
                {animeRating.slice(1, 5).map((animeTop, index) => (
                  
    <div className='rate-item' key={animeTop.id}>
        <p className='rate-position'>#{index + 2}</p>
        <p className='rate-name'>{animeTop.animeName}</p>
        <span className='rate-line'>
        <span style={{ width: `${((randomCounts[index] / maxCount) * 100) * 0.9}%` }} className='rate-status'></span>
        </span>
        <span className='rate-number'>{randomCounts[index]}</span>
    </div>
))}   
                  

                </div>
                
              </div>

              {animeRating[0] && (
    <div className='rating_firstPlace'>
      <h2>#1 | 620 очков</h2>
      <img className='firstPlace_image' src={` http://185.177.219.190/api/images/${animeRating[0].title}`}></img>
      <p className='firstPlace_name'>  {animeRating[0].animeName} </p>
      <div className='title-tags'>

<span>{animeRating[0].animeRating}</span>

<span>FHD</span>

<span>4K</span>

<p>●{animeRating[0].animeType}</p>
</div>
    </div>
  )}
          

              </section>
            </div>
            

            <div className='Main_Content_list trending'>

<h2>В тренде</h2>
<motion.div
      initial={{ opacity: 0, y: -100 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 1 }} 
    >

      <img className='trending_img' src={trending}></img>
<div className='Main_Content_items trending_section '> 
{loading && <LoadingSpinner />}
{animeTranding.map(trendingAnime =>( 
    
    <NavLink to={`/watch/${trendingAnime.collectionId}`} className='Main_Content_item trend-item'>
        <div class="overlay-images"></div>
           <span className='upper-score'> <img src={star}></img> <p> {trendingAnime.animeScore}</p> </span>
           
    <img src={` http://185.177.219.190/api/images/${trendingAnime.title}`}></img>

    <span className='Main_Content_item_text'>

    <h3>{trendingAnime.animeName}</h3>

    <div className='Main_Content_item_info'>
    <p className='Main_Content_item__Year'>{trendingAnime.animeDate}</p>,

    <p className='Main_Content_item__Genre'>{trendingAnime.animeGenre.split(',')[0]}</p>

    </div>
    </span>
    </NavLink>

)
    )}


</div>
</motion.div>
</div>

<div className='Main_Content_Upcoming'>
  <h2>Скоро выйдет</h2>
<div className='Main_Content_Upcoming_wrapper'>



  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#1</span>
  <img src={waited1}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Волчица и пряности: Торговец встречает мудрую волчицу</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>15 янв, 2024</span></div>
</div>
  </div>


  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#2</span>
  <img src={waited2}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Бармен: Бокал бога </h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>4 апреля, 2024</span></div>
</div>
  </div>




  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#3</span>
  <img src={waited3}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Лагерь на свежем воздухе 3</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>15 янв, 2024</span></div>
</div>
  </div>



  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#4</span>
  <img src={waited4}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Ветролом</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>5 апреля, 2024</span></div>
</div>
  </div>


  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#5</span>
  <img src={waited5}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Медуза не умеет плавать в ночи</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>7 апреля, 2024</span></div>
</div>
  </div>


  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#6</span>
  <img src={waited6}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Мой сосед-ёкай</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>7 апреля, 2024</span></div>
</div>
  </div>


  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#7</span>
  <img src={waited7}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Этот замечательный мир! 3</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>7 апреля, 2024</span></div>
</div>
  </div>



  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#8</span>
  <img src={waited8}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Кайдзю номер восемь</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>13 апреля, 2024</span></div>
</div>
  </div>



  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#9</span>
  <img src={waited9}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Моя геройская академия 7</h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>4 мая, 2024</span></div>
</div>
  </div>




  <div className='UpcomingTitle_item'> 
  <span className='title_number'>#10</span>
  <img src={waited10}></img>
<div className='UpcomingTitle_Info'>
  <h2 className='UpcomingTitle_name'>Клинок, рассекающий демонов: Тренировка столпов </h2>
  <div className='UpcomingTitle_additional'> <span className='UpcomingTitle_type'>TV</span> <span className='UpcomingTitle_date'>12 мая, 2024</span></div>
</div>
  </div>








  
</div>
  
</div>



<div className='Main_Content_list popular'>

<h2>Самые популярные</h2>



 <div className='Main_Content_items popular-items'>
      {loading && <LoadingSpinner />}
      {animeRating.slice(0, visibleCount).map(animeTop => (
        <NavLink to={`/watch/${animeTop.collectionId}`} className='Main_Content_item popular-item' key={animeTop.id}>
            <div class="overlay-images"></div>
          <img src={`http://185.177.219.190/api/images/${animeTop.title}`} type="image/jpg" alt={animeTop.animeName} />
          <span className='Main_Content_item_text'>
            <h3>{animeTop.animeName}</h3>
            <div className='Main_Content_item_info'>
              <p className='Main_Content_item__Year'>{animeTop.animeDate}</p>,
              <p className='Main_Content_item__Genre'>{animeTop.animeGenre.split(',')[0]}</p>
            </div>
          </span>
        </NavLink>
      ))}
   
    </div>
    {animeRating.length > visibleCount && (
        <button className='show-more_btn' onClick={showMore}>Показать больше</button> 
      )}
</div>



        </div>

        
       
   </>
        
    )
}

export default Main