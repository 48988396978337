import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/main';
import Catalog from './components/catalog'
import './css/main.css';
import './css/intro.css';
import Header from './components/header';
import Intro from './components/intro.js';
import Footer from './components/footer';
import News from './components/news'
import Collection from './components/collection';
import AnimePage from './components/animePage';
import NotFound from './components/NotFound';
import NewsPage from './components/newsPage';
import UserPageLibrary from './components/userPage';
import UserPageInfo from './components/userPageInfo.js';
import { useState } from 'react';




function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

 

  return (
    <Router>
      <div className="App">
        <Intro />
        <Header  isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path='/catalog' element={<Catalog />}/>
          <Route path='/news' element={<News />}/>
          <Route path='/collection' element={<Collection />} />
          <Route path='/watch/:id' element={<AnimePage/>} />
          <Route path='/news/:id' element={<NewsPage/>} />
          <Route  isLoggedIn={isLoggedIn} path='/UserPageLibrary/:id' element={<UserPageLibrary/>}> </Route>
          <Route  isLoggedIn={isLoggedIn} path='/UserPageInfo/:id' element={<UserPageInfo/>}> </Route>

          <Route path='*' Component={NotFound}></Route>
        </Routes>
        <Footer />
      </div>
      
    </Router>
    
  
  );
}

export default App;
