import React, { useState,useRef,useEffect } from 'react';
import axios from 'axios';
import premium from '../icons/main/premium.svg'

function UserPageInfo() {

const [userInfo,setUserInfo]=useState('')

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('http://185.177.219.190/api/userData', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUserInfo(response.data.user);
               
            } catch (error) {
                console.error('Ошибка при получении данных пользователя:', error);
            }
        };

        fetchUserData();

      
    }, []);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU'); 
    };
    const calculateRemainingDays = (expirationDate) => {
        const oneDay = 24 * 60 * 60 * 1000; 
        const currentDate = new Date();
        const expiration = new Date(expirationDate);
        const differenceInTime = expiration.getTime() - currentDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / oneDay);
        return differenceInDays;
    };

    
    return(
        <>  
                {userInfo && ( 


            
                <div className='Page_info_wrapper'>
                        <div className='page_info__main'>
                            <h2>Информация об аккаунте</h2>
                            <div className='page_info_name  info_item'>

                                <p>Имя аккаунта</p>
                                
                                <p>{userInfo.username}</p>
                            </div>
                            <div className='page_info_email info_item'>

                            <p>Почта</p>
                                
                                <p>{userInfo.email}</p>
                            </div>
                            <div className='page_info_PreferGenres info_item'>

                            <p>предпочитаемые жанры</p>
                                
                            <p>{userInfo.userPreferGenres.join(', ')}</p>

                            </div>
                            <div className='page_info_Description info_item'>

                            <p>О себе</p>
                                
                                <p>{userInfo.userDescription}</p>

                            </div>
                        </div>

                        <div className='page_info__premium'>
                            <span className='premium_banner'>

                                <img src={premium} />
                                <p>ПРЕМИУМ ПОДПИСКА</p>
                            </span>

                            <div className='page_info__premium_main'>
                            <div className='page_info_name  info_item'>

                                <p>Статус подписки</p>
                                
                                <p>{userInfo.premium.status ? 'Активна' : 'Не активна'}</p>
                            </div>
                            <div className=' info_item'>

                            <p>Дата оформления </p>
                                
                              <p>{formatDate(userInfo.premium.start_date)}</p>
                            </div>
                            <div className=' info_item'>

                            <p>Дата истечения</p>
                                
                            <p>{formatDate(userInfo.premium.expiration_date)} (осталось {calculateRemainingDays(userInfo.premium.expiration_date)} дней)</p>
                            </div>
                            <div className=' info_item'>

                        <p>Тип подписки</p>

                        <p>{userInfo.premium.subscription_type}</p>

                            </div>
                            <div className=' info_item'>

                           



                            <p>Стоимость подписки</p>
                                
                                <p>{userInfo.premium.subscription_cost}</p>

                            </div>


                            <div className=' info_item'>

                        <p>QHD/UHD качество</p>
    
                                 <p>{userInfo.premium.quality.QHD_UHD ? 'Доступно' : 'Заблокировано'}</p>

                                </div>

                                
                            <div className=' info_item'>

                    <p>HD/FHD качество</p>

                        <p>{userInfo.premium.quality.HD_FHD ? 'Доступно' : 'Заблокировано'}</p>

                        </div>
                            </div>
                        </div>
                    </div>        
                        )}
        </>
    )
}

export default UserPageInfo