import { useRef,useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from './loadingIcon';
import treasure from '../images/treasure.webp'
import { ReactComponent as PlayIcon } from "../icons/main/play.svg";
import jjk from '../01/1.mp4'
import global from '../icons/main/global.svg'
import local from '../icons/main/local.svg'
import { motion } from "framer-motion";

import resolution from '../icons/main/resolution.svg'
import subtitles from '../icons/main/subtitles.svg'
import stream from '../icons/main/stream.svg'
import dub from '../icons/main/dub.svg'
import bitrate from '../icons/main/bitrate.svg'
import watchedEye from '../icons/main/watched.svg'
import premiumInfo from '../icons/main/premiumInfo.svg'

import Oda from '../Staf/Oda.jpg';
import Naoki from '../Staf/Naoki.jpg'
import Yusuke from '../Staf/Yusuke.jpg'
import Tatsuki from '../Staf/Tatsuki.jpg'
import KarinTakasi from '../Staf/Карин Такаси.jpg'

import Aki from '../Characters/Aki.png'
import Denji from '../Characters/Denji.jpg'
import gojo from '../Characters/gojo.jpg'
import kobeni from '../Characters/Kobeni.jpg'
import Makima from '../Characters/Makima.png'
import Power from '../Characters/Power.jpg'


import one from '../Episodes/01.jpg'
import two from '../Episodes/02.webp'
import three from '../Episodes/03.jpg'
import four from '../Episodes/04.jpg'
import five from '../Episodes/05.jpg'
import six from '../Episodes/06.webp'
import seven from '../Episodes/07.png'
import eight from '../Episodes/08.webp'
import nine from '../Episodes/09.webp'
import ten from '../Episodes/10.webp'
import eleven from '../Episodes/11.jpg'

import collection from "../icons/main/collection.svg";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function AnimePage() {




   
 





    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0);
    const [currentIndex3, setCurrentIndex3] = useState(0);
    const [currentIndex4, setCurrentIndex4] = useState(0);
    const { id: newsId } = useParams();
    const [PageData, setPageData] = useState();
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [images, setImages] = useState([]);
    const [relationsData,setRelationsData] = useState();
    const [volume,setVolume]=useState(0)
    const [trailerActive,setTrailerActive]=useState(false)
    const [loading, setLoading] = useState(true); 
    const [favorites, setFavorites] = useState([]);
    const token = localStorage.getItem('token');

    const [notification, setNotification] = useState(null);

    const [createCollection,setCreateCollection]=useState(false);
    const [collectionData,setCollectionData]=useState();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState(''); 

    const [collectionNew,setCollectionNew]=useState(false);
    const [collectionType,setCollectionType]=useState('global');
   
   
    const [selectedColleciton,setSelectedColleciton] =useState('')

    const [Subscribe,setSubscribe] = useState(false)


    const [activeVideoTab, setActiveVideoTab] = useState('tab1');

    const [watched, setWatched] = useState([]);

    const [watching, setWatching] = useState([]);

    
    const blockRef = useRef(null);

    const [slidesPerView, setSlidesPerView] = useState(3);

   

    const handleVideoTabClick = (tab) => {
      setActiveVideoTab(tab);
    };


    const [activeSpanIndex, setActiveSpanIndex] = useState({
      quality: 1,
      subs: 1,
      sound: 1,
      pipeStream: 1,
      bitrate: 1,
    });

    const handleSpanClick = (section, index) => {
      setActiveSpanIndex(prevState => ({
        ...prevState,
        [section]: index,
      }));
    };

    

    const maxTitleLength = 50; 
    const maxDescriptionLength = 300; 

    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
       if (newTitle.length <= maxTitleLength) {
          setTitle(newTitle);
        }
      };
    
      const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        if (newDescription.length <= maxDescriptionLength) {
          setDescription(newDescription);
        }
      };
      const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
          const formData = {
            title: title,
            description: description,
            type: collectionType
          };
        
          
          const createCollectionResponse = await axios.post(' http://185.177.219.190/api/createCollection', formData, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
      
          const animeResponse = await axios.get(" http://185.177.219.190/api/animeByUserCollection", {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
      
          setCollectionData({
            titles: animeResponse.data.titles,
            collection: animeResponse.data.collection
          });
      
          if (createCollectionResponse.status === 201) {
            showNotification('Подборка успешно создана');
            setCollectionNew(false);
          }
        } catch (error) {
          console.error('Error creating collection:', error);
        }
      };
      
      

     
      useEffect(() => {
        const fetchData = async () => {
          try {

            const response = await axios.post(
              ' http://185.177.219.190/api/setAnimeToCollection',
              {
                collectionID: selectedColleciton,
                animeID: PageData.collectionId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
      

            const secondResponse = await axios.get(" http://185.177.219.190/api/animeByUserCollection", {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
      
            showNotification('Аниме добавлено в подборку');
            setCollectionData({
              titles: secondResponse.data.titles,
              collection: secondResponse.data.collection
            });
      
      
        
         
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
      
        fetchData();
      }, [token, selectedColleciton, createCollection]);

    useEffect(() => {
        const fetchData = async () => {
          try {  
            const response = await axios.get(" http://185.177.219.190/api/animeByUserCollection", {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
          
            setCollectionData({
                titles: response.data.titles,
                collection: response.data.collection
              });
    
        
          } catch (error) {
         
          }
        };
    
        fetchData();
    
        
      }, [createCollection, token])



  
    const fetchFavorites = async (token, setFavorites) => {
      try {
          const response = await axios.get(' http://185.177.219.190/api/userFavoriteAnime', {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          setFavorites(response.data);
      } catch (error) {
          console.error('Ошибка при получении избранных аниме:', error.message);
      }
  };

  const removeFromFavorites = async (animeId) => {
    try {
        const response = await axios.post(' http://185.177.219.190/api/removeFromFavorite', { animeId }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
   


        try {
            const favoritesResponse = await axios.get(' http://185.177.219.190/api/userFavoriteAnime', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setFavorites(favoritesResponse.data);
        } catch (error) {
            console.error('Ошибка при получении избранных аниме:', error.message);
        }
        
    } catch (error) {
        console.error('Ошибка при удалении аниме из избранного:', error.message);
    }
};


const removeFromWatched = async (animeId) => {
  try {
    console.log(animeId)
      const response = await axios.post(' http://185.177.219.190/api/removeFromWatched', { animeId }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
     
          fetchWatched();
   
  } catch (error) {
      console.error('Ошибка при удалении аниме из избранного:', error.message);
  }
};


const removeFromWatching = async (animeId) => {
  console.log(animeId)
  try {
 
      const response = await axios.post(' http://185.177.219.190/api/removeFromWatching', { animeId }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
     
          fetchWatching();
   
  } catch (error) {
      console.error('Ошибка при удалении аниме из избранного:', error.message);
  }
};



    useEffect(() => {
      setLoading(true);
        const fetchImages = async () => {
            try {
                const response = await axios.get(` http://185.177.219.190/api/imagesGallery/${PageData.title}`);
                const relations = await axios.get(` http://185.177.219.190/api/animeRelations`,{
                params:{
                  ids:PageData.animeRelations
                }
                });
                
                setRelationsData(relations.data)
                setImages(response.data.images);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при получении изображений:', error);
            }
        };

        fetchImages();

        fetchFavorites();

       
    }, [PageData]);




    const openFullscreenImage = (index) => {
      setFullscreenImage(images[index]);
  };

  const closeFullscreenImage = () => {
      setFullscreenImage(null);
  };




  const addToFavorites = async (animeId) => {
    try {
        const response = await axios.post(' http://185.177.219.190/api/addToFavorite', { animeId }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        

     
        try {
            const favoritesResponse = await axios.get(' http://185.177.219.190/api/userFavoriteAnime', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            setFavorites(favoritesResponse.data);
        } catch (error) {
            console.error('Ошибка при получении избранных аниме:', error.message);
        }
        
    } catch (error) {
        console.error('Ошибка при добавлении аниме в избранное:', error.message);
    }
};

const isWatching = (animeId) => {
  return watching.some(item => item.collectionId === animeId);
};

const isWatched = (animeId) => {
  return watched.some(item => item.collectionId === animeId);
};

const addToWatched = async (animeId) => {
  try {
      const response = await axios.post(' http://185.177.219.190/api/addToWatched', { animeId }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      try {
          const watchedResponse = await axios.get(' http://185.177.219.190/api/userWatchedAnime', {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });

          fetchWatched();
     
      } catch (error) {
          console.error('Ошибка при получении просмотренных аниме:', error.message);
      }
  } catch (error) {
      console.error('Ошибка при добавлении аниме в просмотренные:', error.message);
  }
};

const addToWatching = async (animeId) => {
  console.log(animeId)
  try {
      const response = await axios.post(' http://185.177.219.190/api/addToWatching', { animeId }, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      try {
          const watchedResponse = await axios.get(' http://185.177.219.190/api/userWatchingAnime', {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });

          fetchWatching();
     
      } catch (error) {
          console.error('Ошибка при получении просмотренных аниме:', error.message);
      }
  } catch (error) {
      console.error('Ошибка при добавлении аниме в просмотренные:', error.message);
  }
};


const fetchWatched = async () => {
  try {
      const response = await axios.get(' http://185.177.219.190/api/userWatchedAnime', {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      setWatched(response.data);
  } catch (error) {
      console.error('Ошибка при получении просмотренных аниме:', error.message);
  }
};

const fetchWatching = async () => {
  try {
      const response = await axios.get(' http://185.177.219.190/api/userWatchingAnime', {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      setWatching(response.data);
  } catch (error) {
      console.error('Ошибка при получении просмотренных аниме:', error.message);
  }
};


useEffect(() => {
  if (token) {
      fetchWatched();
  }
}, [token]);


useEffect(() => {
  if (token) {
      fetchWatching();
  }
}, [token]);



useEffect(() => {
  const fetchFavorites = async () => {
      try {
          const response = await axios.get(' http://185.177.219.190/api/userFavoriteAnime', {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          setFavorites(response.data);
      } catch (error) {
          console.error('Ошибка при получении избранных аниме:', error.message);
      }
  };

  if (token) {
      fetchFavorites();
  }
}, [token]);


useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 700) {
      
      setSlidesPerView(4);
    } else {
      setSlidesPerView(5);
    }
  };

  handleResize(); 
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);




const isFavorite = (animeId) => {
  return favorites.some(favorite => favorite.collectionId === animeId);
};

  


    useEffect(() => {
      setLoading(true);
      const fetchLatestNews = async () => {
          try {
              const PageData = await axios.get(` http://185.177.219.190/api/anime-page/${newsId}`);
              setPageData(PageData.data)
              setLoading(false);
             
          } catch (error) {
              
              console.log(error)
          }
      };

      fetchLatestNews();
  }, [newsId]); 


  
  const [slideWidth, setSlideWidth] = useState(0);
  
  const slideRef = useRef(null);


  useEffect(() => {
    if (slideRef.current) {
      const width = slideRef.current.offsetWidth;
      setSlideWidth(width);
    }
  }, [])

  const prevSlide = () => {
    const newIndex = currentIndex2 === 0 ? images.length - 1 : currentIndex2 - 1;
    setCurrentIndex2(newIndex);
  };


  const nextSlide = () => {
    const newIndex = currentIndex2 === images.length - 1 ? 0 : currentIndex2 + 1;
    setCurrentIndex2(newIndex);
  };

 

  


const nextSlide2 = () => {
    setCurrentIndex2((prevIndex) => (relationsData && relationsData.length > 0 ? (prevIndex === relationsData.length - 1 ? 0 : prevIndex + 1) : 0));
};

const prevSlide2 = () => {
    setCurrentIndex2((prevIndex) => (relationsData && relationsData.length > 0 ? (prevIndex === 0 ? relationsData.length - 1 : prevIndex - 1) : 0));
};



const nextSlide3 = () => {
  setCurrentIndex3((prevIndex) => (PageData && PageData.animeStaff && PageData.animeStaff.length > 0 ? (prevIndex === PageData.animeStaff.length - 1 ? 0 : prevIndex + 1) : 0));
};

const prevSlide3 = () => {
  setCurrentIndex3((prevIndex) => (PageData && PageData.animeStaff && PageData.animeStaff.length > 0 ? (prevIndex === 0 ? PageData.animeStaff.length - 1 : prevIndex - 1) : 0));
};


const nextSlide4 = () => {
    setCurrentIndex4((prevIndex) => (PageData && PageData.animeStaff && PageData.animeStaff.length > 0 ? (prevIndex === PageData.animeStaff.length - 1 ? 0 : prevIndex + 1) : 0));
};

const prevSlide4 = () => {
    setCurrentIndex4((prevIndex) => (PageData && PageData.animeStaff && PageData.animeStaff.length > 0 ? (prevIndex === 0 ? PageData.animeStaff.length - 1 : prevIndex - 1) : 0));
};







        const [activeTab, setActiveTab] = useState(0);
      
        const handleTabClick = (index) => {
          setActiveTab(index);
        };

        const indicatorStyle = {
            left: `${activeTab * 25}%`,
            width: `25%`
          };


    



        
      


      
      
        const scrollToBlock = () => {
          blockRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        
       
    
        const showNotification = (message) => {
            setNotification(message);
            setTimeout(() => {
                setNotification(null);
            }, 5000); 
        };


    
        
    return(
      <motion.div
      initial={{ opacity: 0, y:-100 }}
      animate={{ opacity: 1,y:0 }}
      transition={{ duration: 1 }} 
    >
      <>
  

      <div className={Subscribe?'subscribe-block subscribe-active':'subscribe-block'}>

        <div className='subscribe-block_main'> 
 <img className='subscribe-infoBtn' src={premiumInfo} /> 
       <svg
       className='close-btn'
       onClick={()=>setSubscribe(false)}
       width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#fcfcfcc2">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier">

<path fill="#fcfcfcc2" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>

</g>

</svg>

        <div className='subscribe-title'>
          <span>Оформление подписки</span>
          <h2>Преимущество  <span className='premium-text'> <p>премиума</p></span> </h2>
        </div>

        <div className='subscribe-pros'>

        <ul className='subscribe-checks'>
  <li className='subscribe-check'> <p>Более 1000+ тайтлов на ваш выбор</p> </li>
  <li className='subscribe-check'> <p>Возможность смотреть в  QHD и 4K разрешениях</p> </li>
  <li className='subscribe-check'> <p>Высокое качество битрейта</p> </li>
  <li className='subscribe-check'> <p>Возможность загрузки контента для просмотра офлайн</p> </li>
  <li className='subscribe-check'> <p>Наша личная благодарность от всей команды AnimeFlix</p> </li>
</ul>

<img src={treasure}></img>
        </div>
 
        <div className='subscribe-plans'>

          <div className='subscribe-plan'>
            <h2>Бесплатный</h2>
            <p>7-и дневный период</p>

            <p>Далее 400 руб. в месяц</p>
          </div>
         
          <div className='subscribe-plan'>
            <h2>БАЗОВАЯ <br></br>500 ₽</h2>
            <p>в месяц</p>

            <p> 5.400 ₽ в год</p>
          </div>

          <div className='subscribe-plan'>
            <h2> БАЗОВАЯ+<br></br>800 ₽</h2>
            <p>в месяц</p>

            <p> 7.200 ₽ в год</p>
          </div>
        </div>

        <button className='choose-subscribe'>Оформить подписку</button>

        <p className='restore-premium'>Восстановить подписку</p>
        </div>
      </div>
      <div className={notification?'notification-panel visible-notification':"notification-panel"}>

      <img src={collection}></img>  <p>{notification}</p>
        
      </div>

<div className={collectionNew ? 'collection-new collection-showed' : 'collection-new'}>
      <div className='collection-new-main'>
      <svg
       className='close-btn'
       onClick={() => setCollectionNew(false)}
       width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#fcfcfcc2">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier">

<path fill="#fcfcfcc2" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>

</g>

</svg>
        <h2>Создать подборку</h2>
        <form onSubmit={handleSubmit}>
          <div className='collection-new-title'>
            <h3>Название</h3>
            <input type='text' value={title} onChange={handleTitleChange} maxLength={maxTitleLength} />
            <p>Осталось символов: {maxTitleLength - title.length}/{maxTitleLength}</p>
          </div>
          <div className='collection-new-text'>
            <h3>Описание</h3>
            <textarea type='text' value={description} onChange={handleDescriptionChange} maxLength={maxDescriptionLength} />
            <p>Осталось символов: {maxDescriptionLength - description.length}/{maxDescriptionLength}</p>
          </div>
          <div className='collection-new-type'>
            <h3>Тип подборки</h3>
            <div className='collection_Type_menu'>
              <div
                onClick={() => setCollectionType('global')}
                className={collectionType === 'global' ? "collection_Type_menu_item active-collection_Type" : "collection_Type_menu_item"}> <img src={global}></img> <p>Публичная подборка</p></div>
              <div
                onClick={() => setCollectionType('local')}
                className={collectionType === 'local' ? "collection_Type_menu_item active-collection_Type" : "collection_Type_menu_item"}> <img src={local}></img><p>Личная подборка</p></div>
            </div>
            <button type="submit" className='collection-new-sumbit'>Создать</button>
          </div>
        </form>
      </div>
    </div>

        

            <div 
            className={createCollection?'collection-create':"collection-create colleciton-hidden"}>


                <div className='collection-create-main' >
                <svg
       className='close-btn'
       onClick={()=>setCreateCollection(false)}
       width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#fcfcfcc2">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier">

<path fill="#fcfcfcc2" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>

</g>

</svg>
                <h2>Выберите подборку</h2>  

                <button 
                onClick={()=>setCollectionNew(true)}
                className='collection-create-btn'> + Создать новую</button>
                {collectionData && collectionData.collection && collectionData.collection.map((collection,index) => (
    <div
    onClick={()=>setSelectedColleciton(collection._id)}
    
    className='collection-create-avalible-item'  key={collection._id}>
        <div className='collection-create-avalible-item_info'>
            <h2>{collection.title}</h2>
            <p>{collection.collectionStorage.length} Тайтла(-ов)</p>
        </div>
        {collectionData.titles[index] ? ( 
            <img src={` http://185.177.219.190/api/images/${collectionData.titles[index]}`} alt={collection.titles} />
        ) : (
            <div className='no-preview'> 
        
            <p>Нет превью</p>
            </div>
        )}
    </div>
))}




                </div>
            </div>



      {loading && <LoadingSpinner />}
        {PageData && (
            <>

           <section 
         
           className={trailerActive ? 'trailer-wrapper' : 'hidden'}>
              <span className='trailer-wrapper-layout'></span>

                
              <svg
       className='close-trailer'
       onClick={() => setTrailerActive(false)}
       width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#fcfcfcc2">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier">

<path fill="#fcfcfcc2" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>

</g>

</svg>
            
<div className="video-player trailer-videoplayer">
<div className="video-player" >
    <video controls
     controlsList="nodownload">
        <source 
       
       src={`http://185.177.219.190/api/TraierVideo/${PageData.title}`}
        
        type="video/mp4" />
    </video>
</div>

</div>



</section>
                <div className="page_video-banner" >
                    <video
                        loop
                        autoPlay
                        muted
                        disablePictureInPicture
                    >
                        <source src={` http://185.177.219.190/api/videos/${PageData.title}`} type="video/mp4" />
                    </video>
                    <div className="dark-bg"></div>
                    <button onClick={() => setTrailerActive(!trailerActive)}>  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6935 15.8458L15.4137 13.059C16.1954 12.5974 16.1954 11.4026 15.4137 10.941L10.6935 8.15419C9.93371 7.70561 9 8.28947 9 9.21316V14.7868C9 15.7105 9.93371 16.2944 10.6935 15.8458Z" fill="#000000"/> </g>

</svg>   Трейлер </button>

                    {isWatching(PageData.collectionId) ? (
  <button 
    onClick={() => removeFromWatching(PageData.collectionId)}
    style={{ backgroundColor: 'orangered'  }}
    
  >
     <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6.30147 15.5771C4.77832 14.2684 3.6904 12.7726 3.18002 12C3.6904 11.2274 4.77832 9.73158 6.30147 8.42294C7.87402 7.07185 9.81574 6 12 6C14.1843 6 16.1261 7.07185 17.6986 8.42294C19.2218 9.73158 20.3097 11.2274 20.8201 12C20.3097 12.7726 19.2218 14.2684 17.6986 15.5771C16.1261 16.9282 14.1843 18 12 18C9.81574 18 7.87402 16.9282 6.30147 15.5771ZM12 4C9.14754 4 6.75717 5.39462 4.99812 6.90595C3.23268 8.42276 2.00757 10.1376 1.46387 10.9698C1.05306 11.5985 1.05306 12.4015 1.46387 13.0302C2.00757 13.8624 3.23268 15.5772 4.99812 17.0941C6.75717 18.6054 9.14754 20 12 20C14.8525 20 17.2429 18.6054 19.002 17.0941C20.7674 15.5772 21.9925 13.8624 22.5362 13.0302C22.947 12.4015 22.947 11.5985 22.5362 10.9698C21.9925 10.1376 20.7674 8.42276 19.002 6.90595C17.2429 5.39462 14.8525 4 12 4ZM10 12C10 10.8954 10.8955 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8955 14 10 13.1046 10 12ZM12 8C9.7909 8 8.00004 9.79086 8.00004 12C8.00004 14.2091 9.7909 16 12 16C14.2092 16 16 14.2091 16 12C16 9.79086 14.2092 8 12 8Z" fill="#000000"/> </g>

</svg>

  </button>
) : (
  <button

    onClick={() => addToWatching(PageData.collectionId)}

  >
<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6.30147 15.5771C4.77832 14.2684 3.6904 12.7726 3.18002 12C3.6904 11.2274 4.77832 9.73158 6.30147 8.42294C7.87402 7.07185 9.81574 6 12 6C14.1843 6 16.1261 7.07185 17.6986 8.42294C19.2218 9.73158 20.3097 11.2274 20.8201 12C20.3097 12.7726 19.2218 14.2684 17.6986 15.5771C16.1261 16.9282 14.1843 18 12 18C9.81574 18 7.87402 16.9282 6.30147 15.5771ZM12 4C9.14754 4 6.75717 5.39462 4.99812 6.90595C3.23268 8.42276 2.00757 10.1376 1.46387 10.9698C1.05306 11.5985 1.05306 12.4015 1.46387 13.0302C2.00757 13.8624 3.23268 15.5772 4.99812 17.0941C6.75717 18.6054 9.14754 20 12 20C14.8525 20 17.2429 18.6054 19.002 17.0941C20.7674 15.5772 21.9925 13.8624 22.5362 13.0302C22.947 12.4015 22.947 11.5985 22.5362 10.9698C21.9925 10.1376 20.7674 8.42276 19.002 6.90595C17.2429 5.39462 14.8525 4 12 4ZM10 12C10 10.8954 10.8955 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8955 14 10 13.1046 10 12ZM12 8C9.7909 8 8.00004 9.79086 8.00004 12C8.00004 14.2091 9.7909 16 12 16C14.2092 16 16 14.2091 16 12C16 9.79086 14.2092 8 12 8Z" fill="#000000"/> </g>

</svg>
  </button>
)}



                </div>
                <motion.div
      initial={{ opacity: 0, x: -100 }} 
      animate={{ opacity: 1, x: 0 }} 
      transition={{ delay: 1 }} 
    >
                <div className='anime_main-info'>
       
                    <div className='anime_main-info_image'>
       
                    <img src={` http://185.177.219.190/api/images/${PageData.title}`}></img>   
                    </div>
                  
                    
                    <div className='anime_main-info_title'>
                        <h2>{PageData.animeName}</h2>
                        <h3>{PageData.JapName}</h3>
                        <div className='title-tags'>

                          <span>{PageData.animeRating}</span>

                          <span>FHD</span>

                          <span>4K</span>

                          <p>●{PageData.animeType}</p>
                        </div>
                        <span className='rating'>
                          <div className='rating-webSite'>
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fcfcfc"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M11.2691 4.41115C11.5006 3.89177 11.6164 3.63208 11.7776 3.55211C11.9176 3.48263 12.082 3.48263 12.222 3.55211C12.3832 3.63208 12.499 3.89177 12.7305 4.41115L14.5745 8.54808C14.643 8.70162 14.6772 8.77839 14.7302 8.83718C14.777 8.8892 14.8343 8.93081 14.8982 8.95929C14.9705 8.99149 15.0541 9.00031 15.2213 9.01795L19.7256 9.49336C20.2911 9.55304 20.5738 9.58288 20.6997 9.71147C20.809 9.82316 20.8598 9.97956 20.837 10.1342C20.8108 10.3122 20.5996 10.5025 20.1772 10.8832L16.8125 13.9154C16.6877 14.0279 16.6252 14.0842 16.5857 14.1527C16.5507 14.2134 16.5288 14.2807 16.5215 14.3503C16.5132 14.429 16.5306 14.5112 16.5655 14.6757L17.5053 19.1064C17.6233 19.6627 17.6823 19.9408 17.5989 20.1002C17.5264 20.2388 17.3934 20.3354 17.2393 20.3615C17.0619 20.3915 16.8156 20.2495 16.323 19.9654L12.3995 17.7024C12.2539 17.6184 12.1811 17.5765 12.1037 17.56C12.0352 17.5455 11.9644 17.5455 11.8959 17.56C11.8185 17.5765 11.7457 17.6184 11.6001 17.7024L7.67662 19.9654C7.18404 20.2495 6.93775 20.3915 6.76034 20.3615C6.60623 20.3354 6.47319 20.2388 6.40075 20.1002C6.31736 19.9408 6.37635 19.6627 6.49434 19.1064L7.4341 14.6757C7.46898 14.5112 7.48642 14.429 7.47814 14.3503C7.47081 14.2807 7.44894 14.2134 7.41394 14.1527C7.37439 14.0842 7.31195 14.0279 7.18708 13.9154L3.82246 10.8832C3.40005 10.5025 3.18884 10.3122 3.16258 10.1342C3.13978 9.97956 3.19059 9.82316 3.29993 9.71147C3.42581 9.58288 3.70856 9.55304 4.27406 9.49336L8.77835 9.01795C8.94553 9.00031 9.02911 8.99149 9.10139 8.95929C9.16534 8.93081 9.2226 8.8892 9.26946 8.83718C9.32241 8.77839 9.35663 8.70162 9.42508 8.54808L11.2691 4.41115Z" stroke="#fcfcfc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            <p>{PageData.animeScore}</p>
                            </div>

                            <div className='rating-webSite'>
                           <svg width="202" height="202" viewBox="0 0 202 202" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="201" height="201" fill="black"/>
<path d="M161 41L72.5771 88.3297L118.657 41H93.1143L61.7429 84.5446V41H41V161H61.7429V117.526L93.1143 161H118.657L73.7737 115.158L161 161V138.714L81.4434 106.798L161 112.143V89.8571L81.9474 94.9966L161 63.2857V41Z" fill="url(#paint0_radial_4902_369)"/>
<rect x="0.5" y="0.5" width="201" height="201" stroke="#474747"/>
<defs>
<radialGradient id="paint0_radial_4902_369" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(41 41) rotate(45) scale(169.706)">
<stop offset="0.5" stop-color="#FF5500"/>
<stop offset="1" stop-color="#BBFF00"/>
</radialGradient>
</defs>
</svg>

                            <p>{PageData.kinopoiskScore}</p>
                            </div>

                            <div className='rating-webSite'>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-label="IMDb" role="img" viewBox="0 0 512 512" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><rect width="512" height="512" rx="15%" fill="#f5c518"></rect><path d="M104 328V184H64v144zM189 184l-9 67-5-36-5-31h-50v144h34v-95l14 95h25l13-97v97h34V184zM256 328V184h62c15 0 26 11 26 25v94c0 14-11 25-26 25zm47-118l-9-1v94c5 0 9-1 10-3 2-2 2-8 2-18v-56-12l-3-4zM419 220h3c14 0 26 11 26 25v58c0 14-12 25-26 25h-3c-8 0-16-4-21-11l-2 9h-36V184h38v46c5-6 13-10 21-10zm-8 70v-34l-1-11c-1-2-4-3-6-3s-5 1-6 3v57c1 2 4 3 6 3s6-1 6-3l1-12z"></path></g></svg>
                            <p>{PageData.imdbScore}</p>
                            </div>
                        </span>
                        <div className='anime_main-info_title__buttons'>
                 

                            <button onClick={scrollToBlock} >           
                           
                      <PlayIcon/>     <p>Смотреть</p>           </button> 
                            {isFavorite(PageData.collectionId) ? (
    <button onClick={() => removeFromFavorites(PageData.collectionId)}>
      
      <span className='utf-bookmark_anime-page'>✅</span>   <span>В избранном</span>  
    </button>
) : (
    <button onClick={() => addToFavorites(PageData.collectionId)}>
    
    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier"> <path d="M5 6.2C5 5.07989 5 4.51984 5.21799 4.09202C5.40973 3.71569 5.71569 3.40973 6.09202 3.21799C6.51984 3 7.07989 3 8.2 3H15.8C16.9201 3 17.4802 3 17.908 3.21799C18.2843 3.40973 18.5903 3.71569 18.782 4.09202C19 4.51984 19 5.07989 19 6.2V21L12 16L5 21V6.2Z" stroke="#ffffff" stroke-width="2" stroke-linejoin="round"/> </g>

</svg>  <span>В избранное</span>
    </button>
)}

{isWatched(PageData.collectionId) ? (
    <button onClick={() => removeFromWatched(PageData.collectionId)}>
  <span className='utf-bookmark_anime-page'>✅</span>
        <span>Просмотрено</span>
    </button>
) : (
    <button onClick={() => addToWatched(PageData.collectionId)}>
    <img src={watchedEye}></img>  <span>В просмотренное</span>
    </button>
)}

    <button onClick={()=>setCreateCollection(true)}> <span>+</span> добавить в подборку</button>
                        </div>
                    </div>
                </div>
         </motion.div>
                {fullscreenImage && (
                        <div className="fullscreen-image-overlay">
                            <div className="fullscreen-image-container">
                                <img src={`data:${fullscreenImage.contentType};base64,${fullscreenImage.data.toString('base64')}`} />
                             
                                <svg
       className='close-btn'
       onClick={closeFullscreenImage}
       width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#fcfcfcc2">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

<g id="SVGRepo_iconCarrier">

<path fill="#fcfcfcc2" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>

</g>

</svg>
                               
                            </div>
                        </div>
                    )}

<div className='anime_main-additional'>
                    <div className='anime_main-additional__panel'>
                        <div className="tabs-container">
                          
                <div className="tabs-container">
      <div className="tabs">
        <div
          className={`tab ${activeTab === 0 ? 'active' : ''}`}
          onClick={() => handleTabClick(0)}
        >
          Обзор
        </div>
        <div
          className={`tab ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleTabClick(1)}
        >
          Связи
        </div>
        <div
          className={`tab ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => handleTabClick(2)}
        >
          Персонажи
        </div>
        <div
          className={`tab ${activeTab === 3 ? 'active' : ''}`}
          onClick={() => handleTabClick(3)}
        >
          Сотрудники
        </div>
       
        <div className="indicator" style={indicatorStyle}></div>
        
        <div className="indicator" style={{ left: `${activeTab * 25}%` }}></div>
      </div>
      <div className="tab-content">
        {activeTab === 0 && <div>
            
        <div className='content-review'> 

        <div className='Details'>
            <h2>Детали</h2>
            
            <div className='details-info'>

                <p>Тип</p>

                <p>{PageData.animeType}</p>
            </div>

            <div className='details-info'>

<p>Эпизоды</p>

<p> {PageData.animeEpisodes}</p>
</div>



<div className='details-info'>

<p>Жанры</p>

<p> {PageData.animeGenre}</p>
</div>


<div className='details-info'>

<p>В эфире</p>

<p> {PageData.animeAir}</p>
</div>


<div className='details-info'>

<p>Статус</p>

<p> {PageData.animeStatus}</p>
</div>

<div className='details-info'>

<p>Сезон</p>

<p> {PageData.animeSeason}</p>
</div>


<div className='details-info'>

<p>Студия</p>

<p> {PageData.animeStudios}</p>
</div>


<div className='details-info'>

<p>Рейтинг</p>

<p>{PageData.animeRating}</p>
</div>

<div className='details-info'>

<p>Длительность</p>

<p> {PageData.animeDuration}</p>
</div>

         </div>



        <div className='Description'>
        <h2>Описание</h2>

        <p>
          {PageData.animeDescription}
        </p>
             </div>
        </div>
            
            </div>}


        {activeTab === 1 && <div className='relations'>
            <h2>Хронология</h2>
            <div className='relations_main'>
             
        <Swiper
      modules={[Navigation, Scrollbar]}
      navigation
      spaceBetween={30}
      slidesPerView={slidesPerView}
      className='anime-gallery_main slider'
    
    >
     
     
               
                  {relationsData && relationsData.map && (
  relationsData.map(relate => (
    <SwiperSlide className='Main_Content_item relations-item'>
             <div class="overlay-images"></div>
    <NavLink to={`/watch/${relate.collectionId}`}  key={relate.collectionId}>
      <img src={` http://185.177.219.190/api/images/${relate.title}`} alt={relate.title} />
      <span className='Main_Content_item_text'>
        <h3>{relate.animeName}</h3>
        <div className='Main_Content_item_info'>
          <p className='Main_Content_item__Year'>{relate.animeDate}</p>,
          <p className='Main_Content_item__Genre'>{relate.animeGenre.split(',')[0]}</p>
        </div>
      </span>
    </NavLink>
    </SwiperSlide>
  ))
)}
               
                          
                            
                            </Swiper>   
                      
              
              
 </div>


                 

            </div>}
        {activeTab === 2 && <div className='characters'>
            <h2>Аниме персонажи</h2>
            
            <div className='characters-main'>
            <Swiper
        modules={[Navigation, Scrollbar]}
        navigation
        spaceBetween={20}
        slidesPerView={slidesPerView}
        className='anime-gallery_main slider'
    >
     

           
          

<SwiperSlide className=' Main_Content_item characters-item'>
 
      <img src={Denji} />
      <span className='Main_Content_item_text anime-characters'>
        <h3>Denji</h3>
      </span>
   
    </SwiperSlide>

    <SwiperSlide className='Main_Content_item characters-item'>

      <img src={Aki} />
      <span className='Main_Content_item_text anime-characters'>
        <h3>Aki</h3>
      </span>
    
    </SwiperSlide>

    <SwiperSlide className='Main_Content_item characters-item'>
   
      <img src={gojo} />
      <span className='Main_Content_item_text anime-characters'>
        <h3>Gojo</h3>
      </span>
    
    </SwiperSlide>

    <SwiperSlide className='Main_Content_item characters-item'>
   
      <img src={kobeni} />
      <span className='Main_Content_item_text anime-characters'>
        <h3>Kobeni</h3>
      </span>
    
    </SwiperSlide>

    <SwiperSlide className='Main_Content_item characters-item'>
    
      <img src={Makima} />
      <span className='Main_Content_item_text anime-characters'>
        <h3>Makima</h3>
      </span>
 
    </SwiperSlide>

    <SwiperSlide  className='Main_Content_item characters-item'>
    
      <img src={Power} />
      <span className='Main_Content_item_text anime-characters'>
        <h3>Power</h3>
      </span>
   
    </SwiperSlide>


   
         
          
          </Swiper>
            </div>
            
             </div>
           
            }
        {activeTab === 3 && <div className='characters'>
            <h2>Персонал</h2> 
            
            
            <div className='characters-main'>
            <Swiper
        modules={[Navigation, Scrollbar]}
        navigation
        spaceBetween={20}
        slidesPerView={slidesPerView}
        className='anime-gallery_main slider'
      
    >
     

          
                 
         
    
    <SwiperSlide className='Main_Content_item characters-item' >
      <div>
  
       <img src={KarinTakasi}></img>
        <span className='Main_Content_item_text anime-characters'>
          <h3 className='staff-name'>Karin Takasi</h3>
          <div className='Main_Content_item_info'>
            <p className='Main_Content_item__person staff-role'>Мангака</p>
          </div>
        </span>
      </div>
    </SwiperSlide>



    <SwiperSlide className='Main_Content_item characters-item' >
      <div>
  
       <img src={Oda}></img>
        <span className='Main_Content_item_text anime-characters'>
          <h3 className='staff-name'>Eiichiro  Oda</h3>
          <div className='Main_Content_item_info'>
            <p className='Main_Content_item__person staff-role'>Мангака</p>
          </div>
        </span>
      </div>
    </SwiperSlide>



    <SwiperSlide className='Main_Content_item characters-item' >
      <div>
  
       <img src={Naoki}></img>
        <span className='Main_Content_item_text anime-characters'>
          <h3 className='staff-name'>Naoki Urasawa</h3>
          <div className='Main_Content_item_info'>
            <p className='Main_Content_item__person staff-role'>Мангака</p>
          </div>
        </span>
      </div>
    </SwiperSlide>



    <SwiperSlide className='Main_Content_item characters-item' >
      <div>
  
       <img src={Yusuke}></img>
        <span className='Main_Content_item_text anime-characters'>
          <h3 className='staff-name'>Yusuke Murata</h3>
          <div className='Main_Content_item_info'>
            <p className='Main_Content_item__person staff-role'>Мангака</p>
          </div>
        </span>
      </div>
    </SwiperSlide>


    
    <SwiperSlide className='Main_Content_item characters-item' >
      <div>
  
       <img src={Tatsuki}></img>
        <span className='Main_Content_item_text anime-characters'>
          <h3 className='staff-name'>Tatsuki Fujimoto</h3>
          <div className='Main_Content_item_info'>
            <p className='Main_Content_item__person staff-role'>Мангака</p>
          </div>
        </span>
      </div>
    </SwiperSlide>


   
   </Swiper>
       
        
            </div>
            </div>
          
           }

      </div>
</div>          

                        </div>
                    </div>
                </div>
                <div className='main-video-section'> 
                <motion.div
      initial={{ opacity: 0, y: -100 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 1 }} 
    >
                <div className="video-tabs">
        <button className={activeVideoTab === 'tab1' ? 'tab-active' : 'video-tab'} onClick={() => handleVideoTabClick('tab1')}>  <p>Серии</p></button>
        <button className={activeVideoTab === 'tab2' ? 'tab-active' : 'video-tab'} onClick={() => handleVideoTabClick('tab2')}> <p> Настройки</p></button>
       
      </div>
      </motion.div>
      <div className="tab-video-content">
        {activeVideoTab === 'tab1' && 
        
        <>
          <h2>Серии</h2>
          <div className='episodes-list'>
          <div className='episode-item '>
  <div className='dark-bg'></div>
  <img src={one} alt="Episode 1"></img>
  <p>Серия 1 - untitled</p>
</div>

<div className='episode-item '>
  <div className='dark-bg'></div>
  <img src={two} alt="Episode 2"></img>
  <p>Серия 2 - untitled</p>
</div>

<div className='episode-item '>
  <div className='dark-bg'></div>
  <img src={three} alt="Episode 3"></img>
  <p>Серия 3 - untitled</p>
</div>

<div className='episode-item '>
  <div className='dark-bg'></div>
  <img src={four} alt="Episode 4"></img>
  <p>Серия 4 - untitled</p>
</div>

<div className='episode-item'>
  <div className='dark-bg'></div>
  <img src={five} alt="Episode 5"></img>
  <p>Серия 5 - untitled</p>
</div>

<div className='episode-item'>
  <div className='dark-bg'></div>
  <img src={six} alt="Episode 6"></img>
  <p>Серия 6 - untitled</p>
</div>

<div className='episode-item active-episode'>
  <div className='dark-bg'></div>
  <img src={seven} alt="Episode 7"></img>
  <p>Серия 7 - untitled</p>
</div>

<div className='episode-item '>
  <div className='dark-bg'></div>
  <img src={eight} alt="Episode 8"></img>
  <p>Серия 8 - untitled</p>
</div>

<div className='episode-item '>
  <div className='dark-bg'></div>
  <img src={nine} alt="Episode 9"></img>
  <p>Серия 9 - untitled</p>
</div>

<div className='episode-item'>
  <div className='dark-bg'></div>
  <img src={ten} alt="Episode 10"></img>
  <p>Серия 10 - untitled</p>
</div>

<div className='episode-item '>
  <div className='dark-bg'></div>
  <img src={eleven} alt="Episode 11"></img>
  <p>Серия 11 - untitled</p>
</div>
    

          </div>


          </>}
        {activeVideoTab === 'tab2' && <>
        <h2>Настройки</h2>
        <div className='setting-list'>
      <div className='quality setting-item'> 
         <img src={resolution}/> <p>Качество:</p> 
        <span className={`setting-option ${activeSpanIndex.quality === 1 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('quality', 1)}>1080p</span> 
        <span className={`setting-option ${activeSpanIndex.quality === 2 ? 'active-setting' : ''}`} onClick={() => setSubscribe(true)}>2K</span> 
        <span className={`setting-option ${activeSpanIndex.quality === 3 ? 'active-setting' : ''}`} onClick={() => setSubscribe(true)}>4K</span>
      </div>
      <div className='subs setting-item'>
      <img src={subtitles}/>
        <p>Субтитры:</p>
        <span className={`setting-option ${activeSpanIndex.subs === 1 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('subs', 1)}>RU</span>
        <span className={`setting-option ${activeSpanIndex.subs === 2 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('subs', 2)}>JP</span>
        <span className={`setting-option ${activeSpanIndex.subs === 3 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('subs', 3)}>EN</span>
      </div>
      <div className='sound setting-item'>
      <img src={dub}/>
        <p>Озвучка:</p>
        <span className={`setting-option ${activeSpanIndex.sound === 1 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('sound', 1)}>RU</span>
        <span className={`setting-option ${activeSpanIndex.sound === 2 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('sound', 2)}>EN</span>
      </div>
      <div className='pipe-stream setting-item'>
      <img src={stream}/>
        <p>Адаптивная потоковая передача:</p>
        <span className={`setting-option ${activeSpanIndex.pipeStream === 1 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('pipeStream', 1)}>ВКЛ</span>
        <span className={`setting-option ${activeSpanIndex.pipeStream === 2 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('pipeStream', 2)}>ВЫКЛ</span>
      </div>
      <div className='bitrate setting-item'>
        <img src={bitrate}></img>
        <p>Качество битрейта:</p>
        <span className={`setting-option ${activeSpanIndex.bitrate === 1 ? 'active-setting' : ''}`} onClick={() => handleSpanClick('bitrate', 1)}>СТАНДАРТНОЕ</span> 
        <span className={`setting-option ${activeSpanIndex.bitrate === 2 ? 'active-setting' : ''}`} onClick={() => setSubscribe(true)}>ВЫСОКОЕ</span>
        <span className={`setting-option ${activeSpanIndex.bitrate === 3 ? 'active-setting' : ''}`} onClick={() => setSubscribe(true)}>САМОЕ ВЫСОКОЕ</span>
      </div>
    </div>
        </>}
      
      </div>


      <div ref={blockRef} className='video-main-container'>
            <span className='trailer-wrapper-layout'></span>
      
            <div className="video-player" >
    <video controls
     controlsList="nodownload">
        <source src={jjk} type="video/mp4" />
    </video>
</div>
        </div>



                  </div>

                  <div className='anime-gallery'>
      <h2>Галерея</h2>

      <Swiper
        modules={[Navigation, Scrollbar]}
        navigation
        spaceBetween={20}
        slidesPerView={slidesPerView}
        className='anime-gallery_main slider'
    >
     

      
       
          {images.map((image, index) => (
            <SwiperSlide key={index} className='carousel-slide' onClick={() => openFullscreenImage(index)}>
              <img src={`data:${image.contentType};base64,${image.data.toString('base64')}`} />
            </SwiperSlide>
          ))}
        
      
      

     </Swiper>
      
     
    </div>
                
            </>
        )}
    </>

    </motion.div>  


       
     

    )
}

export default AnimePage

