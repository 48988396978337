import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import LoadingSpinner from './loadingIcon';
import { motion } from "framer-motion";
function News() {
    const [latestNews, setLatestNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [allNews,setAllNews] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(true); 
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        setLoadingStatus(true);
        const fetchLatestNews = async () => {
            try {
                const response = await axios.get(' http://185.177.219.190/api/latest-news'); 
                const allNewsResponse = await axios.get (' http://185.177.219.190/api/all-news');
                setLatestNews(response.data);
                setAllNews(allNewsResponse.data);
            } catch (error) {
                setError(error.message);
            }
            setLoading(false);
            setLoadingStatus(false);
        };

        fetchLatestNews();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    
    return(
        
        <motion.div
        initial={{ opacity: 0,  }} 
        animate={{ opacity: 1,  }} 
        transition={{ delay: 0.2 }} 
      >
        <div className="News_wrapper">
            {loadingStatus && <LoadingSpinner />}
            {latestNews && (
                <div className="News_wrapper__banner">
                    <img src={` http://185.177.219.190/api/NewsImages/Banner/${latestNews.NewsId}`} />
                    <span className='dark-bg'></span>
                    <div className='News_wrapper__banner_text'>
                        <span>{formatDate(latestNews.createdAt)}</span>
                        <h2>{latestNews.NewsTitle}</h2>
                        <NavLink to={`/news/${latestNews.NewsId}`}><button>Подробнее</button></NavLink> 
                    </div>
                </div>
            )}

            <div className='News_wrapper__main'>
                <div className='News_wrapper__content'>
                {loadingStatus && <LoadingSpinner />}
                    {allNews.map((news,index) => (
                        
                        <motion.div
                        key={news.collectionId}
                        initial={{ opacity: 0, y: 50 }}
                        className='News_wrapper__content_item'
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          delay: index * 0.19,
                          duration: 2,
                          type: 'spring',
                        }}
                      >

                        <NavLink key={news.NewsId} to={`/news/${news.NewsId}`} >  
                            <img src={` http://185.177.219.190/api/NewsImages/Banner/${news.NewsId}`} /> 
                            <div className='News_wrapper__content_text'>
                                <span>{formatDate(news.createdAt)}</span>
                                <p>{news.NewsTitle}</p>
                            </div>
                            </NavLink>
                            </motion.div>
                    ))}
                </div>
            </div>
        </div>

        </motion.div>
    );
}

export default News;
