import React, { useEffect, useRef } from 'react';
import petal from '../images/petal.png';


function Intro() {
  const canvasRef = useRef(null);
  const requestIdRef = useRef(null);
  const isCanvasVisibleRef = useRef(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const TOTAL = 150;
    const petalArray = [];

    const petalImg = new Image();
    petalImg.src = petal;
    petalImg.onload = () => {
      for (let i = 0; i < TOTAL; i++) {
        petalArray.push(new Petal());
      }
      render();
    };

    function render() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      petalArray.forEach(petal => {
        petal.animate();
      });

      requestIdRef.current = window.requestAnimationFrame(render);
    }

    class Petal {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height * 2 - canvas.height;
        this.w = 30 + Math.random() * 15;
        this.h = 20 + Math.random() * 10;
        this.opacity = this.w / 45;
        this.xSpeed = 2 + Math.random();
        this.ySpeed = 1 + Math.random() * 0.5;
        this.flip = Math.random();
        this.flipSpeed = Math.random() * 0.03;
      }

      draw() {
        if (this.y > canvas.height || this.x > canvas.width) {
          this.x = -petalImg.width;
          this.y = Math.random() * canvas.height * 2 - canvas.height;
          this.xSpeed = 2 + Math.random();
          this.ySpeed = 1 + Math.random() * 0.5;
          this.flip = Math.random();
        }
        ctx.globalAlpha = this.opacity;
        ctx.drawImage(
          petalImg,
          this.x,
          this.y,
          this.w * (0.66 + (Math.abs(Math.cos(this.flip)) / 3)),
          this.h * (0.8 + (Math.abs(Math.sin(this.flip)) / 2)),
        );
      }

      animate() {
        this.x += this.xSpeed;
        this.y += this.ySpeed;
        this.draw();
        this.flip += this.flipSpeed;
      }
    }

    return () => {
      cancelAnimationFrame(requestIdRef.current);
    };
  }, []);

  return (
   
    <div className="intro-wrapper" ref={containerRef}>
      <canvas ref={canvasRef} className="cherry"></canvas>
      <span className="intro-line"></span>
 
 <p className="intro-title">AnimeFlix</p>

      <div className="block-wrapper">
        <div className="block1"></div>
        <div className="block2"></div>
      </div>
    </div>
  
  );
  
}



export default Intro;
