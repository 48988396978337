import React, { useState, useEffect } from 'react';
import arrow from '../icons/main/arrow.svg';
import relevance from '../icons/main/relevance.svg';
import popularity from '../icons/main/popularity.svg';
import axios from 'axios';
import LoadingSpinner from './loadingIcon';
import { motion } from "framer-motion";
import { NavLink  } from 'react-router-dom';
function Catalog() {
    const [animeList, setAnimeList] = useState([]);
    const [genres, setGenres] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [studios, setStudios] = useState([]);
    const [formats, setFormats] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(true); 
    
    const toggleFilters = (key, value) => {
        setFilters(prevFilters => {
            const updatedFilters = { ...prevFilters };
            if (key === 'animeYearFrom' || key === 'animeYearTo') {
                updatedFilters[key] = value;
            } else {
                if (updatedFilters[key] && updatedFilters[key].includes(value)) {
                    updatedFilters[key] = updatedFilters[key].filter(item => item !== value);
                    if (updatedFilters[key].length === 0) {
                        delete updatedFilters[key];
                    }
                } else {
                    updatedFilters[key] = [...(updatedFilters[key] || []), value];
                }
            }
        
            if (key === 'animeGenre') {
                updatedFilters.animeGenreFilter = Object.values(updatedFilters[key]);
            }
            return updatedFilters;
        });
    };


    const MotionNavLink = motion(NavLink);

    
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
      
                
                const animeListResponse = await axios.get(' http://185.177.219.190/api/allAnime', { params: filters });
                setAnimeList(animeListResponse.data);

                const genresResponse = await axios.get(' http://185.177.219.190/api/genres');
                setGenres(genresResponse.data);
                
                
                const seasonsResponse = await axios.get(' http://185.177.219.190/api/seasons');
                setSeasons(seasonsResponse.data);

                const studiosResponse = await axios.get(' http://185.177.219.190/api/studios');
                setStudios(studiosResponse.data);

                const formatsResponse = await axios.get(' http://185.177.219.190/api/formats');
                setFormats(formatsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
       
        fetchData();
        setLoading(false);
    }, [filters]);

    
    const [isOpen, setIsOpen] = useState({
        year: false,
        season: false,
        genre: false,
        studio: false,
        format: false
    });

    const toggleContent = (tab) => {
        setIsOpen(prevState => ({
            ...prevState,
            [tab]: !prevState[tab]
        }));
    };

    const [isToggles, setIsToggles] = useState(false);

    const toggleSortingContent = () => {
        setIsToggles(!isToggles);
    };

    return (
        

<motion.div
initial={{ opacity: 0, }} 
animate={{ opacity: 1,  }} 
transition={{ duration: 1 }} 
>

        <div className="catalog_wrapper">
            <div className="catalog_title">
                <h2>Каталог</h2>
                <span className="sorting">
                    <p onClick={toggleSortingContent}>Отсортировать </p>
                    <img onClick={toggleSortingContent}  className={`arrow-icon sorting-icon ${isToggles ? "arrow-active" : ""}`} src={arrow} alt="Arrow Icon" />
                    {isToggles && (
                        <div className='sorting-content'>
                            <div className='sorting-content-item'>
                                <img src={relevance} alt="Relevance Icon" />
                                <p>Релевантные</p>
                            </div>
                            <div className='sorting-content-item'>
                                <img src={popularity} alt="Popularity Icon" />
                                <p>Популярные</p>
                            </div>
                        </div>
                    )}
                </span>
            </div>

            <div className='catalog_main'>
                <div className='catalog_sidebar'>
                    <div className='catalog_item years_tool'>
                   
                        <div className='catalog_item__title' onClick={() => toggleContent('year')}>
                            <p>Год</p>
                            <span></span>
                          
                            <img
                                className={`arrow-icon year-icon ${isOpen.year ? 'arrow-active' : ''}`}
                                src={arrow}
                                alt="Arrow Icon"
                            />
                            
                        </div>
                        
                        <div className={`catalog_item__content ${isOpen.year ? 'open' : ''}`}>
                        <form className='year-selection'>
    <input
        type='number'
        placeholder="Год с"
        value={filters.animeYearFrom || ''}
        onChange={(e) => toggleFilters('animeYearFrom', parseInt(e.target.value))}
    />
    <input
        type='number'
        placeholder="Год по"
        value={filters.animeYearTo || ''}
        onChange={(e) => toggleFilters('animeYearTo', parseInt(e.target.value))}
    />
</form>
                        </div>
                    </div>

                    <div className='catalog_item season_tool'>
                        <div className='catalog_item__title' onClick={() => toggleContent('season')}>
                            <p>Сезон</p>
                            <span></span>
                            <img
                                className={`arrow-icon season-icon ${isOpen.season ? 'arrow-active' : ''}`}
                                src={arrow}
                                alt="Arrow Icon"
                            />
                        </div>
                        <div className={`catalog_item__content ${isOpen.season ? 'open' : ''}`}>
                            <form className='season-selection'>
                                {seasons.map( seasonList =>(


                              
                                <label>
                                    <input type="checkbox" 
                                 onChange={() => toggleFilters('animeSeason', seasonList)}
                                    />
                                    <span></span>
                                    {seasonList}
                                </label>
                                  ))}
                            
                            </form>
                        </div>
                    </div>

                    <div className='catalog_item genre_tool'>
                        <div className='catalog_item__title' onClick={() => toggleContent('genre')}>
                            <p>Жанр</p>
                            <span></span>
                            <img
                                className={`arrow-icon genre-icon ${isOpen.genre ? 'arrow-active' : ''}`}
                                src={arrow}
                                alt="Arrow Icon"
                            />
                        </div>
                        <div className={`catalog_item__content ${isOpen.genre ? 'open' : ''}`}>
                            <form className='genre-selection'>
                                {genres.map(genre =>(


                             
                                <label>
                                    <input type="checkbox"
                                      onChange={() => toggleFilters('animeGenreFilter', genre)}
                                    />
                                    <span></span>
                                    {genre}
                                </label>
                                  ))}
                            </form>
                        </div>
                    </div>

                    <div className='catalog_item studio_tool'>
                        <div className='catalog_item__title' onClick={() => toggleContent('studio')}>
                            <p>Студия</p>
                            <span></span>
                            <img
                                className={`arrow-icon studio-icon ${isOpen.studio ? 'arrow-active' : ''}`}
                                src={arrow}
                                alt="Arrow Icon"
                            />
                        </div>
                        <div className={`catalog_item__content ${isOpen.studio ? 'open' : ''}`}>
                            <form className='studio-selection'>
                                {studios.map (studioList =>(

                             
                                <label>
                                    <input type="checkbox" 
                                    onChange={() => toggleFilters('animeStudios', studioList)}

                                    />
                                    <span></span>
                                    {studioList}
                                </label>
                                 ) 
                                 )}
                            </form>
                        </div>
                    </div>

                    <div className='catalog_item format_tool'>
                        <div className='catalog_item__title' onClick={() => toggleContent('format')}>
                            <p>Формат</p>
                            <span></span>
                            <img
                                className={`arrow-icon format-icon ${isOpen.format ? 'arrow-active' : ''}`}
                                src={arrow}
                                alt="Arrow Icon"
                            />
                        </div>
                        <div className={`catalog_item__content ${isOpen.format ? 'open' : ''}`}>
                            <form className='format-selection'>
                                {formats.map( formatList =>(


                             
                                <label>
                                    <input type="checkbox"
                                   onChange={() => toggleFilters('animeType', formatList)}
                                    />
                                    <span></span>
                                    {formatList}
                                </label>
                                   ))}
           
                            </form>
                        </div>
                    </div>
                </div>

                <div className='catalog_content'>   
           


    {loading && <LoadingSpinner />}
    {animeList.map((anime, index) => (
           <motion.div
           key={anime.collectionId}
           initial={{ opacity: 0, y: 50 }}
           className='Main_Content_item item-catalog'
           animate={{ opacity: 1, y: 0 }}
           transition={{
             delay: index * 0.09,
             duration: 0.7,
             type: 'spring',
           }}
         >
          <NavLink to={`/watch/${anime.collectionId}`} >
          <div class="overlay-images"></div>
            <img src={`http://185.177.219.190/api/images/${anime.title}`} alt={anime.title} />
            <span className='Main_Content_item_text'>
              <h3>{anime.animeName}</h3>
              <div className='Main_Content_item_info'>
                <p className='Main_Content_item__Year'>{anime.animeDate}</p>
                <p className='Main_Content_item__Genre'>{anime.animeGenre.split(',')[0]}</p>
              </div>
            </span>
           
          </NavLink>
          </motion.div>
      ))}
   
  


    


    
                         


                </div>

        
            </div>
        </div>
        </motion.div>
    );
}

export default Catalog;
