import React, { useState, useEffect } from 'react';
import star from '../icons/main/star.svg';
import close from '../icons/main/close.svg';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion";
import global from "../icons/main/global.svg"
import local from "../icons/main/local.svg";
import collection from "../icons/main/collection.svg";


function Collection() {
    
    const [collectionAnime, setCollectionAnime] = useState([]);
    const [isCollection, setCollection] = useState(false);
    const [selectedCollectionId, setSelectedCollectionId] = useState('');
    const [selectedAnime, setSelectedAnime] = useState([]);
    const [collectionName,setCollectionName] = useState('');
    const [collectionText,setCollectionText]=useState('')
    const [loading, setLoading] = useState(true);
    const [collectionNew,setCollectionNew]=useState(false);
    const token = localStorage.getItem('token');

    const [notification, setNotification] = useState(null);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState(''); 
    const [collectionType,setCollectionType]=useState('global');

   
    const maxTitleLength = 50; 
    const maxDescriptionLength = 300; 


    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
       if (newTitle.length <= maxTitleLength) {
          setTitle(newTitle);
        }
      };
    
      const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        if (newDescription.length <= maxDescriptionLength) {
          setDescription(newDescription);
        }
      };
      const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
          const formData = {
            title: title,
            description: description,
            type: collectionType
          };
    
          const response = await axios.post('http://185.177.219.190/api/createCollection', formData, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
  
          if (response.status === 201) {
            showNotification('Подборка успешно создана');
            setCollectionNew(false)
         

        }

    
        } catch (error) {
          console.error('Error creating collection:', error);

        }
      }; 

      const showNotification = (message) => {
        setNotification(message);
        setTimeout(() => {
            setNotification(null);
        }, 5000); 
    };



    useEffect(() => {
        setLoading(true);
        if (selectedCollectionId !== null) {
            axios.get(` http://185.177.219.190/api/animeByCollection`, {
                params: {
                    collectionIds: selectedCollectionId
                }
            })
            .then(response => {
                setSelectedAnime(response.data);
                setLoading(false);
                
            })
            .catch(error => {
                console.error('Ошибка при загрузке аниме:', error);
            });
        }
    }, [selectedCollectionId]);

    useEffect(() => {
        setLoading(true);
        axios.get(' http://185.177.219.190/api/collectionMainPage')
          .then(response => {
            setCollectionAnime(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Ошибка при получении данных:', error);
          });
      }, []); 

    const toggleCollection = () => {
        setCollection(!isCollection);
    };

    return (
        <>
      
      <div className={notification?'notification-panel visible-notification':"notification-panel"}>

<img src={collection}></img>  <p>{notification}</p>

</div>


        <div className={collectionNew ? 'collection-new collection-showed' : 'collection-new'}>
      <div className='collection-new-main'>
        <img className='close-btn' onClick={() => setCollectionNew(false)} src={close} alt="Close Button" />
        <h2>Создать подборку</h2>
        <form onSubmit={handleSubmit}>
          <div className='collection-new-title'>
            <h3>Название</h3>
            <input type='text' value={title} onChange={handleTitleChange} maxLength={maxTitleLength} />
            <p>Осталось символов: {maxTitleLength - title.length}/{maxTitleLength}</p>
          </div>
          <div className='collection-new-text'>
            <h3>Описание</h3>
            <textarea type='text' value={description} onChange={handleDescriptionChange} maxLength={maxDescriptionLength} />
            <p>Осталось символов: {maxDescriptionLength - description.length}/{maxDescriptionLength}</p>
          </div>
          <div className='collection-new-type'>
            <h3>Тип подборки</h3>
            <div className='collection_Type_menu'>
              <div
                onClick={() => setCollectionType('global')}
                className={collectionType === 'global' ? "collection_Type_menu_item active-collection_Type" : "collection_Type_menu_item"}><img src={global} alt="Global" /><p>Публичная подборка</p></div>
              <div
                onClick={() => setCollectionType('local')}
                className={collectionType === 'local' ? "collection_Type_menu_item active-collection_Type" : "collection_Type_menu_item"}><img src={local} alt="Local" /><p>Личная подборка</p></div>
            </div>
            <button type="submit" className='collection-new-sumbit'>Создать</button>
          </div>
        </form>
      </div>
    </div>

        



            <div className={`Collection_main ${isCollection ? 'collection-showed' : ""}`}>
                <div className="CollectionPage_bg"></div>
                <div className={`CollectionPage_main ${isCollection ? 'active-collection' : ""}`}>
                    <img className='close-btn' onClick={toggleCollection} src={close} alt="Close Button"/>
                    <h2>{collectionName}</h2>
                    <div className='collection-Text'><p>{collectionText}</p></div>
                 
                    
                    {selectedAnime.map((selectedAnimePopup,index) => (
                        <NavLink to={`/watch/${selectedAnimePopup.collectionId}`} className='CollectionPage_main_item' key={selectedAnimePopup.id}>
                            <span className='CollectionPage_main_item-number'><p>{index+1}</p></span>
                            <div className='CollectionPage_main_item-main'>
                                <h2>{selectedAnimePopup.animeName}</h2>
                                <p>{selectedAnimePopup.animeBannerDescription}</p>
                            </div>
                            <span className='CollectionPage_main_item-rate'>
                                <img src={` http://185.177.219.190/api/images/${selectedAnimePopup.title}`} alt="Friren"/>
                                <span className='collection-rate'>
                                    <img src={star} alt="Star Icon"/> <p>{selectedAnimePopup.animeScore}</p>
                                </span>
                            </span>
                        </NavLink>
                    ))}
                </div>
            </div>

            <div className="Collection_wrapper">
                <div className="Collection_wrapper__title">
                    <h2>Подборки</h2>
                    <button
                      onClick={()=>setCollectionNew(true)}
                    > + Создать новую</button>
                </div>

                <motion.div
      initial={{ opacity: 0, y: -100 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ delay: 0.4 }} 
    >
                <div className="Collection_wrapper__main">
                {Array.isArray(collectionAnime.mergedCollection) && collectionAnime.mergedCollection.map((collection, index) => (
    <div 
        onClick={() => {
            setCollection(true);
            setSelectedCollectionId(collection.collectionStorage);
            setCollectionName(collection.title);
            setCollectionText(collection.collectionText);
        }}
        className='Main_Content_Collections__item best-collection' key={index}
    >
        <h3>{collection.title || collection.collectionTitle}</h3>
       
        {collection.titleStorage && collection.titleStorage.length > 0 && (
            <>
                {collection.titleStorage[0] && (
                    <div className='first-card'>
                        <img src={` http://185.177.219.190/api/images/${collection.titleStorage[0] || collection.title}`} />
                    </div>
                )}
                {collection.titleStorage[1] && (
                    <div className='second-card'>
                        <img src={` http://185.177.219.190/api/images/${collection.titleStorage[1] || collection.title}`} />
                    </div>
                )}
                {collection.titleStorage[2] && (
                    <div className='third-card'>
                        <img src={` http://185.177.219.190/api/images/${collection.titleStorage[2] || collection.title}`} />
                    </div>
                )}
            </>
        )}
    </div>
))}

                </div>

                </motion.div>
            </div>
        </>
    );  
}

export default Collection;
