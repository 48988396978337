import React from 'react';
import '../css/404.css'

const NotFound = () => {
  return (
    <div className='not-found'>
      <h1>404 - Страница не найдена</h1>
    </div>
  );
};

export default NotFound;