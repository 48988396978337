import React, { useState,useRef,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import close from '../icons/main/close.svg';
import tengen from '../images/Uzui.png';
import rias from '../images/rias.png';
import reg from '../icons/main/reg.svg';
import log from '../icons/main/login.svg';
import arrow from '../icons/main/arrow.svg';
import vk from '../icons/main/vkontakte.svg';
import google from '../icons/main/google.svg';
import library from '../icons/main/library.svg';
import status from '../icons/main/status.svg';
import exit from '../icons/main/exit.svg';

import home from '../icons/main/home.svg';
import news from '../icons/main/news.svg';
import catalogue from '../icons/main/catalogue.svg'
import shounen from '../images/PreferGenres/Shounen.jpg'
import action from '../images/PreferGenres/action.jpg'
import comedy from '../images/PreferGenres/comedy.jpg'
import fantasy from '../images/PreferGenres/fantasy.png'
import journey from '../images/PreferGenres/journey.webp'
import supernatural from '../images/PreferGenres/supernatural.jpg'
import romance from '../images/PreferGenres/romance.jpg'
import isekai from '../images/PreferGenres/isekai.png'
import meha from '../images/PreferGenres/meha.jpg'
import sport from '../images/PreferGenres/sport.webp'
import psychological from '../images/PreferGenres/psychological.jpg'
import scary from '../images/PreferGenres/scary.webp'
import drama from '../images/PreferGenres/drama.jpg'
import everyday from '../images/PreferGenres/everyday.jpg'
import detective from '../images/PreferGenres/detective.jpg'
import { motion } from 'framer-motion';

function Header() {
    const [query, setQuery] = useState('');
    const [animeData, setAnimeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [registration, setRegistration] = useState(false);
    const [login,setLogin]=useState(false)
    const searchRef = useRef(null);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(null);
    const [profileMeu,setProfileMenu] =useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginError, setLoginError] = useState('');


    const [AccountSetup,setAccountSetup]=useState(false);
    const [stateA, setStateA] = useState(false);
    const [stateB, setStateB] = useState(false);
    const [stateC, setStateC] = useState(false);
    const [currentParagraph, setCurrentParagraph] = useState(0);


    const [description, setDescription] = useState(''); 
    const [genres, setGenres] = useState([]);

    const [setupStatus,setSetupStatus]=useState(false)

    const [mobileMenu,setMobileMenu] = useState(false)
   


    const [selectedImage, setSelectedImage] = useState(null);
    const [clickedOutside, setClickedOutside] = useState(false);
    const formRef = useRef(null);


 useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setClickedOutside(true);
      } else {
        setClickedOutside(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
    
    const setMobileMenuFunction =()=>{
        
        setMobileMenu(prevState => !prevState);
    }
    const handleClick = (genre) => {
        if (genres.includes(genre)) {
 
          setGenres(genres.filter(item => item !== genre));
        } else {
          setGenres([...genres, genre]);
        }
      };


      const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setSelectedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    
      const handleDragOver = (e) => {
        e.preventDefault();
      };
      const handleSelectAnother = () => {
        setSelectedImage(null);
      };
    
    

    useEffect(() => {
      const timeout = setTimeout(() => {
        setCurrentParagraph(prev => (prev < 2 ? prev + 1 : prev));
      }, 3000); 
  
      return () => clearTimeout(timeout);
    }, [currentParagraph]);

    

    
    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(' http://185.177.219.190/api/userData', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUserData(response.data.user);
        } catch (error) {
            console.error('Ошибка при получении данных пользователя:', error);
        }
    };


    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
            fetchUserData(); 
        }
    }, [isLoggedIn,]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchUserData(); 
        }
    }, [isLoggedIn]);

    const handleSearch = async () => {
        try {
            setLoading(true);
            const response = await axios.get(' http://185.177.219.190/api/search', {
                params: {
                    query: query
                }
            });
            setAnimeData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Ошибка при выполнении поискового запроса:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (query.trim() !== '') {
            handleSearch();
        } else {
            setAnimeData([]);
        }
    }, [query]);
   
    const handleLogout = () => {
        setIsLoggedIn(false);
        setUserData(null);
        localStorage.removeItem('token');
        localStorage.removeItem('registrationStatus');
        setAccountSetup(false);
        window.location.href = '/';
    };


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !username || !password) {
            setErrorMessage('Пожалуйста, заполните все поля');
            return;
        }
    
        try {
            console.log('Отправка запроса на сервер...');
            const response = await axios.post(' http://185.177.219.190/api/userReg', {
                email,
                username,
                password,
                premium: {
                    status: false, 
                    start_date: new Date(), 
                    expiration_date: new Date(), 
                    subscription_type: 'Не оформлена',
                    subscription_cost: '',
                    quality: {
                      QHD_UHD: false,
                      HD_FHD: false,
               
                    }
                  }
                
            });
            setErrorMessage('');
            setEmailError('');
            setUsernameError('');
            setIsLoggedIn(true);
            setRegistration(false);
    
            setAccountSetup(true)
    
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('registrationStatus', 'registrationSetup');

            
        } catch (error) {
            console.error('Ошибка при регистрации:', error.response.data);
    
            if (error.response.data.emailError) {
                setEmailError(error.response.data.emailError);
            }
    
            if (error.response.data.usernameError) {
                setUsernameError(error.response.data.usernameError);
            }
    
            if (error.response.data.error) {
                setErrorMessage(error.response.data.error);
            }
        }
    };
    
    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        if (!loginEmail || !loginPassword) {
            setLoginError('Пожалуйста, заполните все поля');
            return;
        }
    
        try {
            console.log('Отправка запроса на сервер...');
            const response = await axios.post(' http://185.177.219.190/api/userLogin', {
                email: loginEmail,
                password: loginPassword
            });
            setLoginError('');
            setIsLoggedIn(true);
            setLogin(false)
            localStorage.setItem('token', response.data.token);
            window.location.reload()
        } catch (error) {
            console.error('Ошибка при входе:', error.response.data);
    
            if (error.response.data.error) {
                setLoginError(error.response.data.error);
            }
        }
    };
    useEffect(() => {
        
        const registrationStatus = localStorage.getItem('registrationStatus');
         if (registrationStatus === 'registrationSetup') {
            setAccountSetup(true); 
        }
        if (AccountSetup ||registrationStatus === 'registrationSetup' ) {
            const timerA = setTimeout(() => {
                setStateA(true);
                setStateB(false);
              }, 400);
          
              const timerB = setTimeout(() => {
                  setStateA(false);
                 setStateB(true);
              }, 3500);

              const timerC = setTimeout(() => {
               setStateB(false);
               setStateC(true)
            }, 6500);
      
              const clearTimers = () => {
                clearTimeout(timerA);
                clearTimeout(timerB);
                clearTimeout(timerC);
              };
          
             
              return clearTimers;
         
            
        }
    }, [localStorage.getItem('registrationStatus')] );


    const sendDataToApi = async () => {
        try {
            const token = localStorage.getItem('token');
            const registrationStatus = localStorage.getItem('registrationStatus');
            if (registrationStatus === 'registrationSetup') {
                const response = await axios.post(
                    ' http://185.177.219.190/api/userSetup', 
                    {
                        description,
                        genres,
                        selectedImage
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }

                        
                    }
                );

                
                setSetupStatus(true);
                
                setTimeout(() => {
                    
                    const reloadInterval = setInterval(() => {
                 
                        window.location.reload();
                     
                        clearInterval(reloadInterval);
                    }, 2000); 
                }, 1000);
              
                
            }
        } catch (error) {
            console.error('Error:', error); 
        }
    };
  
   

    

    return (
        <>


{AccountSetup && (
                <div className="registration-setup-block">
               
                    <div className='registration-setup-block-main'>
                    <div className={ setupStatus?'setup-status-complete status-complete':'setup-status-complete '}>

<p>Аккаунт успешно настроен 👍</p>
</div>

                    <div className={stateC?'welcome-text introudece-disabled':"welcome-text"}>
                        <p className={stateA?'step-1 text-step active-step':'step-1 text-step '}>Привет, давай настроим твой аккаунт 🖐️</p>
                        <p  className={stateB?'step-2 text-step active-step':'step-2 text-step '}>Это не займёт много времени ⚙️</p>
                      
                        </div>
                        <div className={stateC?'account_setup__main':'account_setup__main introudece-disabled'}>
                        <div className='account_setup__main-image-section'>
    <h2>Выберите изображение профиля</h2>
    <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className='dragable-image-setup'
        style={{ border: selectedImage ? 'none' : '2px dashed orangered' }}
    >
        {selectedImage ? (
            <img
                className='image-preview-setup'
                src={selectedImage}
                alt="Selected"
            />
        ) : (
            <p>Перетащите изображение сюда</p>
        )}
        {selectedImage && (
            <button
                className='set-another-image'
                onClick={handleSelectAnother}
            >
                Выбрать другое изображение
            </button>
        )}
    </div>
    <input
    type="file"
    accept="image/*"
    id='upload-button'
    onChange={(e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }}
    style={{
        display: 'none'
    }}
/>
<label
    className='custom-file-upload'
    htmlFor="upload-button"
>
    Загрузить изображение
</label>
</div>

                        <div className='account_setup__main-about-section'>
                            <h2>Расскажите немного о себе</h2>

                            <textarea type='text'
                              onChange={(e) => setDescription(e.target.value)}
                               value={description}
                            
                            />


                        </div>


                        <div className={ setupStatus?'account_setup__main-prefer-section prefer-disabled': 'account_setup__main-prefer-section'}>
                            <h2>Какие жанры вы предпочитаете?</h2>
                            <div className='account_setup__main-genres'>
        <div onClick={() => handleClick('Экшен')} className={`account_setup__main-genre ${genres.includes('Экшен') ? 'active-genre' : ''}`}><img src={action}></img><p> 🔥 Экшен</p></div>
        <div onClick={() => handleClick('Сёнен')} className={`account_setup__main-genre ${genres.includes('Сёнен') ? 'active-genre' : ''}`}><img src={shounen}></img><p> ⚔️Сёнен</p></div>
        <div onClick={() => handleClick('Комедия')} className={`account_setup__main-genre ${genres.includes('Комедия') ? 'active-genre' : ''}`}><img src={comedy}></img><p>😂 Комедия</p></div>
        <div onClick={() => handleClick('Фэнтези')} className={`account_setup__main-genre ${genres.includes('Фэнтези') ? 'active-genre' : ''}`}><img src={fantasy}></img><p>🔮 Фэнтези</p></div>
        <div onClick={() => handleClick('Приключение')} className={`account_setup__main-genre ${genres.includes('Приключение') ? 'active-genre' : ''}`}><img src={journey}></img><p>🧭Приключение</p></div>
        <div onClick={() => handleClick('Сверхъестественное')} className={`account_setup__main-genre ${genres.includes('Сверхъестественное') ? 'active-genre' : ''}`}><img src={supernatural}></img><p> 👻Сверхъестественное</p></div>
        <div onClick={() => handleClick('Романтика')} className={`account_setup__main-genre ${genres.includes('Романтика') ? 'active-genre' : ''}`}><img src={romance}></img><p>❤️Романтика</p></div>
        <div onClick={() => handleClick('Исекай')} className={`account_setup__main-genre ${genres.includes('Исекай') ? 'active-genre' : ''}`}><img src={isekai}></img><p>🧙‍♂️ Исекай</p></div>
        <div onClick={() => handleClick('Меха')} className={`account_setup__main-genre ${genres.includes('Меха') ? 'active-genre' : ''}`}><img src={meha}></img><p>🤖 Меха</p></div>
        <div onClick={() => handleClick('Спорт')} className={`account_setup__main-genre ${genres.includes('Спорт') ? 'active-genre' : ''}`}><img src={sport}></img><p>🏃🏻 Спорт</p></div>
        <div onClick={() => handleClick('Психологический')} className={`account_setup__main-genre ${genres.includes('Психологический') ? 'active-genre' : ''}`}><img src={psychological}></img><p>🎭 Психологический</p></div>
        <div onClick={() => handleClick('Ужасы')} className={`account_setup__main-genre ${genres.includes('Ужасы') ? 'active-genre' : ''}`}><img src={scary}></img><p>💀 Ужасы</p></div>
        <div onClick={() => handleClick('Драма')} className={`account_setup__main-genre ${genres.includes('Драма') ? 'active-genre' : ''}`}><img src={drama}></img><p> 🥀 Драма</p></div>
        <div onClick={() => handleClick('Повседневность')} className={`account_setup__main-genre ${genres.includes('Повседневность') ? 'active-genre' : ''}`}><img src={everyday}></img><p>🌃 Повседневность</p></div>
        <div onClick={() => handleClick('Детектив')} className={`account_setup__main-genre ${genres.includes('Детектив') ? 'active-genre' : ''}`}><img src={detective}></img><p> 🕵 Детектив</p></div>
      </div>
                        </div>


                        <button className='account_setup_btn' onClick={() => {
    sendDataToApi();
    localStorage.setItem('registrationStatus', 'disabled');
    fetchUserData();

    
}}>Подтвердить</button>


                        </div>
                    </div>
                </div>
            )}

            
            <div className={registration ? 'registration-form' : 'registration-form deactivated-form'}>
                <div className='registration-main'>
                    <img onClick={() => setRegistration(!registration)} className='form-close-btn' src={close} />
                    <img className='form-close-first-img' src={tengen} />
                    <img className='form-close-second-img' src={rias} />
                    <h2>Создать аккаунт</h2>
                    <form onSubmit={handleSubmit}>
                    <div className='form-auth form-input'>
                            <div className='vk social-auth'>
                                <img src={vk}></img>
                            </div>
                            <div className='google social-auth'>
                            <img src={google}></img>
                            </div>
                        </div>
                        <div className='email form-input'>
                            <p>Почта</p>
                            <input
                                type='email'
                                placeholder='example@yandex.ru'
                                value={email}
                                onChange={handleEmailChange}
                            />
                            {emailError && <div className='error-form-message'>{emailError}</div>}
                        </div>
                        <div className='username form-input'>
                            <p>Имя пользователя</p>
                            <input
                                type='text'
                                placeholder='Введите ваше имя пользователя'
                                value={username}
                                onChange={handleUsernameChange}
                            />
                            {usernameError && <div className='error-form-message'>{usernameError}</div>}
                        </div>
                        <div className='password form-input'>
                            <p>Пароль</p>
                            <input
                                type='password'
                                placeholder='Придумайте надёжный пароль'
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </div>
                     
                        <button type='submit' className='submit-btn'>Зарегистрироваться</button>
                        {errorMessage && <div className='error-form-message'>{errorMessage}</div>}
                    </form>
                </div>
            </div>

            <div className={login ? 'login-form' : 'registration-form deactivated-form'}>
                <div className='registration-main'>
                    <img onClick={() => setLogin(!login)} className='form-close-btn' src={close} />
                    <img className='form-close-first-img' src={tengen} />
                    <img className='form-close-second-img' src={rias} />
                    <h2>Вход</h2>
                    <form onSubmit={handleLoginSubmit}>
                        <div className='email form-input'>
                            <p>Почта</p>
                            <input
                                type='email'
                                placeholder='example@yandex.ru'
                                value={loginEmail}
                                onChange={(event) => setLoginEmail(event.target.value)}
                            />
                            {emailError && <div className='error-form-message'>{emailError}</div>}
                        </div>
                      
                        <div className='password form-input'>
                          <div className='pasword-title'>  <p>Пароль</p>  <p>Забыли пароль?</p></div> 
                            <input
                                type='password'
                                placeholder='Пароль'
                                value={loginPassword}
                                onChange={(event) => setLoginPassword(event.target.value)}
                            />
                        </div>
                        {loginError && <div className='error-form-message'>{loginError}</div>}
                        <div className='form-auth form-input'>
                            <div className='vk social-auth'>
                                <img src={vk}></img>
                            </div>
                            <div className='google social-auth'>
                            <img src={google}></img>
                            </div>
                        </div>

                        <button type='submit' className='submit-btn'>Войти</button>
      
                        <div className='suggest-block'>
                        <p>Нет аккаунта? <span onClick={() => {setLogin(false); setRegistration(true)}}>Зарегистрируйтесь</span></p>
                        </div>
                        {errorMessage && <div className='error-form-message'>{errorMessage}</div>}
                    </form>
                </div>
            </div>



            <header>
                <NavLink to="/"> <h1 className='logo-desktop'>AnimeFlix</h1> </NavLink>
                <nav> 
                    <NavLink to="/"> <img src={home}></img> <p>Главная</p> </NavLink>
                    <NavLink to="/catalog"> <img src={catalogue}></img> <p>Каталог</p> </NavLink>
                    <NavLink to="/news"> <img src={news}></img>  <p>Новости</p> </NavLink>
                    <NavLink to="/collection"> <img src={library} /> <p>Подборки</p> </NavLink>
                </nav>

                <div onClick={setMobileMenuFunction} className={mobileMenu ? 'mobile-menu mobile-open' : 'mobile-menu'}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <section className={mobileMenu?'mobileMenu_main ':"mobileMenu_main disabled-menu "}>
            
                <nav>
                  <NavLink to="/"> <h1 className='logo-mobile'>AnimeFlix</h1> </NavLink>
                    <NavLink to="/"> <p>Главная</p> </NavLink>
                    <NavLink to="/catalog"> <p>Каталог</p> </NavLink>
                    <NavLink to="/news"> <p>Новости</p> </NavLink>
                    <NavLink to="/collection"> <p>Подборки</p> </NavLink>
                </nav>
                    
                </section>

                <form  ref={formRef}  className='search-form'>
                    <input
                        type='search'
                        placeholder='Искать..'
                        value={query}
                 
                        onChange={(event) => {
                            setQuery(event.target.value);
                        }}
                    />

{animeData.length > 0 && !loading && !clickedOutside && (
        <div ref={searchRef} className='form-block search-showed'>
            {animeData.map((anime,index) => (
                 <motion.div
                 key={anime.collectionId}
                 initial={{ opacity: 0, y: 50 }}
                
                 animate={{ opacity: 1, y: 0 }}
                 transition={{
                   delay: index * 0.09,
                   duration: 0.7,
                   type: 'spring',
                 }}
               >
                <NavLink to={`/watch/${anime.collectionId}`} key={anime._id}   className='form-block-item'>
                    <img   src={` http://185.177.219.190/api/images/${anime.title}`} className='form-block-item-image' alt='Cover' />
                    <div className='form-block-item-info'>
                        <h2>{anime.animeName}</h2>
                        <p>{anime.animeDescription}</p>
                    </div>
                </NavLink>
                </motion.div>
            ))}
        </div>
    )}

                </form>
                {isLoggedIn && userData ? (
    <div className='profile-section'>
      
            <div onClick={()=>setProfileMenu(!profileMeu)} className='user_profile_menu'>
                    <p>{userData.username}</p> <span><img className={!profileMeu?"rotated":""} src={arrow}/></span>
            
            <div className={profileMeu?'user_profile_menu__main  profile-inactive':'user_profile_menu__main'}>
               <NavLink to={`/UserPageLibrary/${userData._id}`} className='user_profile_menu__main_item'> <img src={library}></img><p>Моя библиотека</p></NavLink>
               <NavLink to={`/UserPageInfo/${userData._id}`} className='user_profile_menu__main_item'>  <img src={status}></img> <p>Аккаунт</p></NavLink>
               <div onClick={handleLogout} className='user_profile_menu__main_item'> <img src={exit}></img><p>Выйти</p></div>
            </div>
                    
            </div>
     
        <img className='user-pfp'  src={` http://185.177.219.190/api/userAvatar/${userData._id}`} />
    </div>
) : (
    <div className='buttons'>
        <button  onClick={() => setLogin(!login)} className='button sign-in'><img src={log} alt="Login" /> <p> Войти</p> </button>
        <button onClick={() => setRegistration(!registration)} className='button reg'> <img src={reg} alt="Registration" /><p> Регистрация</p> </button>
    </div>
)}
            </header>
        </>
    );
}

export default Header;
